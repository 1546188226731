import React, {  useState, useEffect, useRef, useCallback } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { Card } from '@mui/material';
import { InputBase } from '@mui/material';
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import axiosInstance from '../../components/util/AxiosUtil';
import TouchAppRoundedIcon from '@mui/icons-material/TouchAppRounded';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { useInView } from "react-intersection-observer"

import '../../assets/css/photo.css';

const Photo = () => {    

    const navigate = useNavigate();
    const [lat, setLat] = useState(0);//위도
    const [lng, setLng] = useState(0);//경도
    const [RTUList, setRTUList] = useState([]);//RTU 리스트

    const [hasNextPage, setHasNextPage] = useState(true);
    const page = useRef(1);
    const [ref, inView] = useInView();//스크롤이 맨 아래 있을 때 inview에 'true' 값이 세팅됨
    const length = 20;//한 번에 보여줄 개체 수
    const [searchText, setSearchText] = useState('');//검색바

    //버튼 클릭시 화면이동
    const buttonClick = (rtuSeq, siteNm, adr, instlPlndocNo, cids) =>{
        goToDetail(rtuSeq, siteNm, adr, instlPlndocNo, cids);
    };
    const goToDetail = (rtuSeq, siteNm, adr, instlPlndocNo, cids)=>{
        navigate("/product/photo/detail", {replace: false, state: {rtuSeq: rtuSeq, siteNm:siteNm, adr:adr, instlPlndocNo:instlPlndocNo, cids:cids}});
    };

    const fetch = useCallback(() => {
        
        //page를 통해 startNum구하기
        var startNum = 1 + (page.current-1)*length;

        //위경도를 구할 수 있다면
        if(navigator.geolocation){

            getLocation(startNum);

        }else{
            alert('GPS를 지원하지 않습니다.');
            noLatLngAxios(startNum);
        }
      }, []);

    useEffect(()=>{
        //화면이 맨 마지막 div에 도달하면 inView(ref)가 true로 바뀜 => 조회 함수 실행
        if (inView && hasNextPage) {
            fetch();
        }
    }, [fetch, inView]);

    useEffect(()=>{
        const params = new URLSearchParams(window.location.search);

        let search = params.get("search");

        setSearchText(search);

        if (search){            
            onSearch(search);
        }
        
    }, [])

    //위,경도 구하고 데이터 조회
    const getLocation = (startNum) =>{
        navigator.geolocation.getCurrentPosition(function(position){
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);    
    
            const params = new URLSearchParams(window.location.search);
            let search = params.get("search");

            axiosInstance.get('/InstRtuList', {params: {start:startNum, length:length, lat:position.coords.latitude, lng:position.coords.longitude, keyword: search}}).then((Response)=>{
                if (Response.request.status === 200) {  

                    setRTUList((prevPosts) => [...prevPosts, ...Response.data.list]);
                    setHasNextPage(Response.data.list.length === length);
                    if (Response.data.list.length) {
                        page.current += 1;
                    }
                }
            });

        }, function(error){
            noLatLngAxios(startNum);
        }, {
            enableHighAccuracy: false,
            maximumAge: 0,
            timeout: Infinity
        });
    };

    //위도, 경도 없을 경우의 axios
    const noLatLngAxios = (startNum) =>{

        const params = new URLSearchParams(window.location.search);
        let search = params.get("search");

        axiosInstance.get('/InstRtuList', {params: {start:startNum, length:length, lat:lat, lng:lng, keyword: search}}).then((Response)=>{
            if (Response.request.status === 200) {  

                setRTUList((prevPosts) => [...prevPosts, ...Response.data.list]);
                setHasNextPage(Response.data.list.length === length);
                if (Response.data.list.length) {
                    page.current += 1;
                }
            }
    
        });
    }
    
    //엔터로 검색이벤트 생성
    const EnterSearch = (e)=>{
        if(e.key === 'Enter'){
            onSearch(e.target.value);
        }
    }

    //검색 기능
    const onSearch = (e) =>{
        
        page.current = 1;

        axiosInstance.get('/InstRtuList', {params: {start:(page.current-1)*length, length:length, lat:lat, lng:lng, keyword: e}}).then((Response)=>{
            if (Response.request.status === 200) {  
                setRTUList([]);
                setRTUList(() => [...Response.data.list]);
                setHasNextPage(Response.data.list.length === length);
                if (Response.data.list.length) {
                    page.current += 1;
                }
            }
        });
    }

    const cidCustom = (cids) => {

        var cidList = cids.split(',');
        //value.substring(value.length-4 ,value.length)
        return(
            <>
                {cidList.map((value,idx) => (
                    <div key={idx} className="cidsCustom">
                        <span>
                            {value.substring(0,value.length-4)}
                        </span>
                        <span className="colorHighlight">
                            {value.substring(value.length-4 ,value.length)}
                        </span>
                    </div>
                ))}
           </>
        );
    };

    return (
        <div style={{ "color": "rgb(68, 89, 110)", "height": "100%" }}>
        <Grid spacing={2} sx={{ height:'100%'}}>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                <div className="mobileHiddenCustom" style={{"padding": "16px 24px", "marginBottom":"10px"}}>
                    <div style={{ "marginBottom": "16px" }}></div>
                    <p fontWeight="600" className="css-1pr8naw">사진 등록</p>
                    <a className="css-3vvtry">PoSE / 영업 지원 / 사진 등록</a>
                </div>

                <Grid container direction="row" justifyContent="center">
                    <Grid xs={12} sm={12} md={10} lg={10} xl={6}>
                        <Paper className="paperStickyCustom" 
                                elevation={6} 
                                sx={{padding:'5px 20px 5px 20px', border:'4px solid #1976d2', marginTop:'10px'}}
                        >
                            <InputBase fullWidth 
                                        placeholder="검색어를 입력해주세요." 
                                        id="fullWidth" 
                                        color="primary"
                                        value={searchText}
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyUp={EnterSearch}
                            >
                            </InputBase>
                            <IconButton size="large"
                                        onClick={() => onSearch(searchText)}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        <div style={{ position: 'relative' }}>
                        {RTUList?.map((value) => (
                            <React.Fragment key={value.rtuSeq}>
                                <Card className="cardCustom" 
                                        sx={{marginBottom: '10px', padding: '10px'}}
                                        elevation={3}
                                >
                                    <div className="photoSiteNmFont">{value.siteNm}</div>
                                    <div className="flex-sb" style={{"margin":"5px 0 5px 0"}}>
                                        <div className="photoAdr">{value.adr}</div>
                                        <div className="photoSiteKm">{value.km}km</div>
                                    </div>
                                    <Grid display={'flex'} justifyContent={'space-between'}>
                                        <Grid className="instlAndCID" xs={8} sm={8} md={10} lg={10} xl={10}>
                                            <div style={{"marginBottom":"5px"}}>
                                                <span>설치계획서번호: </span>
                                                <span className="colorHighlight">{value.instlPlndocNo}</span>
                                            </div>
                                            <div style={{"display":"flex", "flexWrap":"wrap"}}>
                                                <span>CID: </span>
                                                    {cidCustom(value.cids)}
                                            </div>
                                        </Grid>
                                        <Button variant="contained" 
                                                sx={{width: '90px', height: '40px', fontSize:'14px'}} 
                                                startIcon={<TouchAppRoundedIcon />} 
                                                onClick={() => {buttonClick(value.rtuSeq, value.siteNm, value.adr, value.instlPlndocNo, value.cids)}}
                                        >
                                            선택
                                        </Button>
                                    </Grid>
                                </Card>
                            </React.Fragment>
                        ))}
                         <div ref={ref} style={{ position: 'absolute', bottom: '100px' }} />
                        </div>
                    </Grid>
                </Grid>

            </Grid>
        </Grid>
        
        
        
        
    </div>
    );
}

export default Photo;