import React, {useEffect, useRef, useState} from "react";
import '../../../assets/css/KakaoMap.css'
import axiosInstance from "../../../components/util/AxiosUtil";
import btrPinImg from '../../../assets/image/map_pin01.png'
import oosPinImg from '../../../assets/image/map_pin02.png'
import prePinImg from '../../../assets/image/map_pin04.png'
import {useForm} from "react-hook-form";
import successImage from "../../../assets/image/pin_success.png";
import errorImage from "../../../assets/image/pin_error.png";
import noneImage from "../../../assets/image/pin_none.png";

const { kakao } = window;

const defaultValues = {
    siteSeq: "",
    siteNm: "",
    oosSeq: 0,
    lat: 0,
    lng: 0,
    oosTypeCd: "",
    oosTypeNm: "",
    rtuSeq: "",
}

function IncidentKakaoMap({height, getDtlInfo, movelatLng, setOpen, setTabIdx, searchSetValue, adressSetting}) {

    var markerWidth = '32',
        markerHeight = '35',
        offsetX = '10',
        selectedMarkerWidth = '40',
        selectedMarkerHeight = '45',
        selectedMarkerOffsetX = '17';

    const [map, setMap] = useState(); //map
    const [place, setPlace] = useState(); //장소api
    const [rtuList, setRtuList] = useState([]); //rtu 목록
    const [markers, setMarkers] = useState([]); //마커 배열

    const {
        getValues,
        reset,
        formState: { errors }
    } = useForm( {defaultValues} );

    //전 마커
    let beforeMarker = null;
    var infowindow = new kakao.maps.InfoWindow({zIndex:1});
    const defaultLatLon = new kakao.maps.LatLng(37.47840501810059, 126.86440503565339);


    //지도 핀 목록 조회
    const getPinList = () => {
        axiosInstance.get('/getIncidentPinList').then((Response)=>{
            if (Response.request.status === 200) {
                setRtuList(Response.data.list);
            }
        });
    }

    const dtlInfo = (info) => {
        reset(info);
        getDtlInfo(info);
    }

    //마커 이미지 생성
    const createMarkerImage = (oosTypeNm, width, height, offsetX) => {
        const src = ( oosTypeNm === "btr" ? btrPinImg : oosTypeNm === "pre" ? prePinImg : oosPinImg );
        const size = new kakao.maps.Size(width, height);
        const markerImage = new kakao.maps.MarkerImage(src, size, {offset: new kakao.maps.Point(offsetX, height)});

        return markerImage;
    }

    const createMarkers = () => {

        let markerArray = [];
        for (let i = 0; i < rtuList.length; i++) {
            const position = new kakao.maps.LatLng(rtuList[i].lat, rtuList[i].lng);
            const markerImage = createMarkerImage(rtuList[i].oosTypeNm, markerWidth, markerHeight, offsetX);
            const selectMarkerImage = createMarkerImage(rtuList[i].oosTypeNm,selectedMarkerWidth, selectedMarkerHeight, selectedMarkerOffsetX);

            const marker = new kakao.maps.Marker({position: position, image: markerImage});
            markerArray.push(marker);

            if(map != undefined){
                kakao.maps.event.addListener(marker, 'click', function() {
                    setTabIdx('0');
                    mapPanto(marker, rtuList[i].siteNm);

                    let beforeMarkerImage;
                    if(beforeMarker){
                        beforeMarkerImage = createMarkerImage(getValues().oosTypeNm, markerWidth, markerHeight, offsetX);
                        beforeMarker.setImage(beforeMarkerImage);
                        beforeMarker.setZIndex(0);
                    }

                    //방금 클릭한 마커에 이미지 변경
                    marker.setImage(selectMarkerImage);
                    marker.setZIndex(1);

                    dtlInfo(rtuList[i]);// 현재 선택된 마커정보 저장

                    //그 전 마커 정보 저장
                    beforeMarker = marker;
                });
            }

        }
        setMarkers(markerArray);
    }

    // 제품 마커들의 지도 표시 여부를 설정하는 함수입니다
    const setMarkerList = (map) => {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }

    //핀 툴팁 표기
    const displayInfowindow = (marker, title) => {
        var content = '<div style="padding:5px;z-index:1;width:max-content;left:-17px!important;">' + title + '</div>';
        infowindow.setContent(content);
        infowindow.open(map, marker);
    }

    //클릭한 핀 으로 이동
    const mapPanto = (marker, siteNm) => {
        const level = map.getLevel();

        let offsetY = 0.1;
        let offsetX = 0.08;

        switch(level) {
            case 1:
                offsetY = 0.000218078125;
                offsetX = 0.0003125;
                break;
            case 2:
                offsetY = 0.00043615625;
                offsetX = 0.000625;
                break;
            case 3:
                offsetY = 0.0008723125;
                offsetX = 0.00125;
                break;
            case 4:
                offsetY = 0.001744625;
                offsetX = 0.0025;
                break;
            case 5:
                offsetY = 0.00348925;
                offsetX = 0.005;
                break;
            case 6:
                offsetY = 0.0069785;
                offsetX = 0.01;
                break;
            case 7:
                offsetY = 0.013957;
                offsetX = 0.02;
                break;
            case 8:
                offsetY = 0.027914;
                offsetX = 0.04;
                break;
            case 9:
                offsetY = 0.055828;
                offsetX = 0.08;
                break;
            case 10:
                offsetY = 0.111656;
                offsetX = 0.16;
                break;
            case 11:
                offsetY = 0.223312;
                offsetX = 0.32;
                break;
            case 12:
                offsetY = 0.446624;
                offsetX = 0.64;
                break;
            case 13:
                offsetY =  0.893248;
                offsetX = 1.28;
                break;
            case 14:
                offsetY = 1.786496;
                offsetX = 2.56;
                break;
        }
        const moveLatLon = new kakao.maps.LatLng(marker.getPosition().getLat() - offsetY, marker.getPosition().getLng());
        map.panTo(moveLatLon, { animate: { cancelable: false } });

        //해당 주소 API 조회
        getAddressAPI(marker.getPosition().getLat(), marker.getPosition().getLng());

        setTimeout(()=> {
            infowindow.close();
            displayInfowindow(marker, siteNm);
        }, 500)

    }

    //사이트 조회시 해당 위치로 이동
    const handleMoveMap = (lat, lng, open) => {
        if(!lat) return;
        if(!lng) return;

        //const level = map.getLevel();
        const level = 4;
        map.setLevel(4);

        let offsetY = 0.1;
        let offsetX = 0.08;

        switch(level) {
            case 1:
                offsetY = 0.000218078125;
                offsetX = 0.0003125;
                break;
            case 2:
                offsetY = 0.00043615625;
                offsetX = 0.000625;
                break;
            case 3:
                offsetY = 0.0008723125;
                offsetX = 0.00125;
                break;
            case 4:
                offsetY = 0.001744625;
                offsetX = 0.0025;
                break;
            case 5:
                offsetY = 0.00348925;
                offsetX = 0.005;
                break;
            case 6:
                offsetY = 0.0069785;
                offsetX = 0.01;
                break;
            case 7:
                offsetY = 0.013957;
                offsetX = 0.02;
                break;
            case 8:
                offsetY = 0.027914;
                offsetX = 0.04;
                break;
            case 9:
                offsetY = 0.055828;
                offsetX = 0.08;
                break;
            case 10:
                offsetY = 0.111656;
                offsetX = 0.16;
                break;
            case 11:
                offsetY = 0.223312;
                offsetX = 0.32;
                break;
            case 12:
                offsetY = 0.446624;
                offsetX = 0.64;
                break;
            case 13:
                offsetY =  0.893248;
                offsetX = 1.28;
                break;
            case 14:
                offsetY = 1.786496;
                offsetX = 2.56;
                break;
        }

        const moveLatLon = new kakao.maps.LatLng(lat- offsetY, lng);
        map.panTo(moveLatLon, { animate: { cancelable: false } });
        setOpen(open);
        getAddressAPI(lat, lng);
    }

    //kakao 주소검색 API
    const getAddressAPI = (lat, lng) => {
        var geocoder = new kakao.maps.services.Geocoder();
        var coord = new kakao.maps.LatLng(lat, lng);
        var callback = function(result, status) {
            var adr = "";
            if (status === kakao.maps.services.Status.OK) {
                adr = result[0].address.address_name
            }
            adressSetting(lat, lng, adr);
        };
        geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
    }



    /**
     * 뷰포트 변경에 따른 map resize 함수
     */
    const mapReload = (width) => {
        if(map !== undefined){
            map.relayout();
            console.log("mapReload, width = "+width);
        }

    }
    //디바운스 구현
    const debounce = (func, delay) => {
        let timer;
        return () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func();
            }, delay);
        };
    }
    //과도한 사용 방지
    const debounceResize = debounce(() => {
        mapReload(window.innerWidth);
    }, 1000); // 1000ms

    useEffect(()=>{
        //지도 핀 목록 조회
        getPinList();
        window.addEventListener("resize", debounceResize);
        return () => window.removeEventListener("resize", debounceResize);
    }, [])

    useEffect(() =>{
        //마커 지도표시
        setMarkerList(map);
    }, [markers])

    useEffect(() => {
        if(map === undefined){
            const container = document.getElementById('inc_kakao_map');
            const options = {
                center: defaultLatLon,
                level: 4
            };
            //map
            const mapObj = new kakao.maps.Map(container, options);
            setMap(mapObj);

            //장소검색
            var placeObj = new kakao.maps.services.Places();
            setPlace(placeObj);
        }
        
        createMarkers(); //마커 목록 생성
    }, [rtuList])

    useEffect(() => {
        let open = movelatLng.open ? movelatLng.open : false;
        handleMoveMap(movelatLng.lat, movelatLng.lng, open);
    }, [movelatLng])

    return (
        <div style={{position: 'relative', width: '100%', height:height}}>
            <div id="inc_kakao_map" style={{width: '100%', height: '100%', borderRadius: "15px"}}></div>
        </div>
    )
}

export default IncidentKakaoMap;