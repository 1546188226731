import {Box, Button, Modal, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import axiosInstance from "../../components/util/AxiosUtil";
import CustomTextField from "../../components/ui/CustomTextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CustomAutocomplete from "../../components/ui/CustomAutocomplete";
import Toast from "../../components/ui/Toast";
import Alert from "../../components/ui/Alert";

const defaultValues = {
    numRowYy: "",               //사업연도
    siteNm: '',                 //사이트 명
    rtuSeq: '',                 //rtu일련번호
    oosOccuDt: '',              //고장 발생시간
    oosTypeCd: '',              //고장 유형 코드
    oosTypeNm: '',              //고장 유형 코드 명
    rmark: '',                  //비고
    actnr: '',                  //조치자
    actnDt: null,                 //조치일자
    actnRsltCd: '',             //조치결과
    btrPamDate: null,             //출장예정일
    btrrId: '',                  //출장예정자
}

const listDefaultValues = {
    oosSeq: '',
    numRowYy: "",               //사업연도
    nrnGentCd: '',              //에너지원 코드
    nrnGentNm: '',              //에너지원 코드 명
    siteNm: '',                 //사이트 명
    rtuSeq: '',                 //rtu일련번호
    oosOccuDt: '',              //고장 발생시간
    oosTypeCd: '',              //고장 유형 코드
    oosTypeNm: '',              //고장 유형 코드 명
    oosDtl: '',                 //고장상세
    rmark: '',                  //비고
}


const IncidentMultiModal = ({open, setOpen, checkList, btnType, usrBtrList, actnRsltList, oosTypeCdList}) => {

    const { handleSubmit,
            control,
            getValues,
            reset,
            formState: { errors }
    } = useForm( {defaultValues} );

    const {
        reset: listReset,
        handleSubmit: listHandleSubmit,
        control: listControl,
        getValues: listGetValues,
        setValue : listSetValue,
        formState: {errors: listErrors}
    } = useForm({listDefaultValues})

    const { fields,  remove } = useFieldArray({
        control: listControl,
        name: "oosArray",
    });

    //조치결과 목록 원본
    const [actnChangeList, setActnChangeList] = useState([]);

    // Alert ref 객체
    const alertRef= useRef();
    //toast ref 객체
    const toastRef = useRef();

    //선택된 장애 목록 정보 조회
    const getOosHistInfo = () => {

        console.log(checkList);
        if(checkList.length < 1) return;
        let textHttp = '/getOosArrayList?';
        //list 형식으로 주소 전송
        for(let i=0; i < checkList.length; i++){
            if(i === '0'){
                textHttp += 'strOosSeqs=' + checkList[i];
            }else{
                textHttp += '&strOosSeqs=' + checkList[i];
            }
        }
        axiosInstance.get(textHttp).then((Response)=> {
            if (Response.request.status === 200) {
                listSetValue("oosArray", Response.data.list);
                console.log(Response.data.list);
            }
        });
    }


    //테이블 삭제버튼 클릭 이벤트
    const rowDelClick = (e) => {
        const array = listGetValues("oosArray");

        array.forEach((value, idx) => {
            if(value.oosSeq == e.target.dataset.seq){
                remove(idx);
            }
        })
    }

    //장애유형 변경 이벤트
    const oosTypeChanged = () => {
        const defaultList = actnRsltList;
        if(!getValues().oosTypeCd){
            setActnChangeList(defaultList);
        }else{
            const list = defaultList.filter(obj => {
                return getValues().oosTypeCd === obj.upDtlCd;
            })
            setActnChangeList(list);
        }
    }

    const onSubmit = (data) => {
        if(btnType === 'actn'){
            //조치등록
            actnInsert(data);
        }else if(btnType === 'btr'){
            //출장등록
            btrInsert(data);
        }
    }

    //다중 결과조치
    const actnInsert = (data) => {
        if(!data.actnr){
            alertRef.current.handleOpen("확인", "조치자 입력은 필수입니다.");
            return;
        }
        if(!data.actnDt){
            alertRef.current.handleOpen("확인", "조치일자 입력은 필수입니다.");
            return;
        }
        if(!data.oosTypeCd){
            alertRef.current.handleOpen("확인", "고장 유형 입력은 필수입니다.");
            return;
        }
        if(!data.actnRsltCd){
            alertRef.current.handleOpen("확인", "조치 결과 입력은 필수입니다.");
            return;
        }

        //조회된 장애목록 array + 장애조치 form
        let list = [];
        const array = listGetValues("oosArray");
        array.forEach((value, idx) => {
            const obj = {
                ...value,
                actnDt: data.actnDt,
                actnr: data.actnr,
                oosTypeCd: data.oosTypeCd,
                actnRsltCd: data.actnRsltCd
            }
            list.push(obj);
        })
        console.log(list);

        const jsonString = JSON.stringify(list);
        const tableFormData = new FormData();
        tableFormData.append("jsonString", jsonString);

        axiosInstance.post('/oosActnList', tableFormData).then((Response)=>{
            if(Response.request.status === 200 ){

                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "저장 되었습니다.");
                    setOpen(false);

                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage );
                }
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");
            }
        });
    }

    //다중 출장등록
    const btrInsert = (data) => {
        console.log(data);
        if(!data.btrPamDate){
            alertRef.current.handleOpen("확인", "출장 예정일 입력은 필수입니다.");
            return;
        }
        if(!data.btrrId){
            alertRef.current.handleOpen("확인", "출장 예정자 입력은 필수입니다.");
            return;
        }
        //조회된 장애목록 array + 장애조치 form
        let list = [];
        const array = listGetValues("oosArray");
        array.forEach((value, idx) => {
            const obj = {
                ...value,
                btrPamDate: data.btrPamDate,
                btrrId: data.btrrId
            }
            list.push(obj);
        })
        console.log(list);

        const jsonString = JSON.stringify(list);
        const tableFormData = new FormData();
        tableFormData.append("jsonString", jsonString);

        axiosInstance.post('/oosBtrList', tableFormData).then((Response)=>{
            if(Response.request.status === 200 ){

                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "저장 되었습니다.");
                    setOpen(false);

                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage );
                }
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");
            }
        });
    }

    //장애조치 등록 폼
    const ActnForm = () => (
        <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
            <div style={{
                fontSize: "15px",
                fontWeight: "bold",
                boxShadow: "inset 0 -8px 0 #bbdefb",
                width: "fit-content",
                marginBottom: "15px"
            }}>
                <span>다중 장애 조치</span>
            </div>
            <Grid container spacing={1}>
                {/* 장애 조치일 */}
                <Grid sx={modalHalfStyle}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ko"
                    >
                        <Controller
                            name='actnDt'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    label={['조치일자']}
                                    inputFormat="YYYY-MM-DD"
                                    value={value}
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) =>  {
                                        if (newValue){
                                            return onChange(newValue.format("YYYYMMDD"));
                                        }else{
                                            return onChange(newValue);
                                        }
                                    }}
                                    renderInput={(params) =>  <TextField fullWidth {...params} size="small"/>}
                                    sx={{width:'100%'}}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                {/* 조치자 목록 */}
                <Grid sx={modalHalfStyle}>
                    <CustomAutocomplete fullWidth
                                        name="actnr"
                                        label="조치자"
                                        options={usrBtrList}
                                        value="btrrId"
                                        text="btrrNm"
                                        control={control}
                                        size="small"
                                        getValues={getValues}
                                        errors={errors.actnr}
                    />
                </Grid>

                {/* 장애유형 */}
                <Grid sx={modalHalfStyle}>
                    <CustomAutocomplete fullWidth
                                        name="oosTypeCd"
                                        label="장애유형"
                                        options={oosTypeCdList}
                                        value="dtlCd"
                                        text="dtlCdNm"
                                        control={control}
                                        size="small"
                                        getValues={getValues}
                                        errors={errors.oosTypeCd}
                                        onChange={oosTypeChanged}
                    />
                </Grid>

                {/* 조치결과 */}
                <Grid sx={modalHalfStyle}>
                    <CustomAutocomplete fullWidth
                                        name="actnRsltCd"
                                        label="조치결과"
                                        options={actnChangeList}
                                        value="dtlCd"
                                        text="dtlCdNm"
                                        control={control}
                                        size="small"
                                        getValues={getValues}
                                        errors={errors.actnRsltCd}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
    //출장등록 폼
    const BtrForm = () => (
        <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
            <div style={{
                fontSize: "15px",
                fontWeight: "bold",
                boxShadow: "inset 0 -8px 0 #bbdefb",
                width: "fit-content",
                marginBottom: "15px"
            }}>
                <span>출장 등록</span>
            </div>
            <Grid container spacing={1}>
                {/* 출장 예정일 */}
                <Grid sx={modalHalfStyle}>
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="ko"
                    >
                        <Controller
                            name='btrPamDate'
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <DatePicker
                                    label={['출장 예정일']}
                                    inputFormat="YYYY-MM-DD"
                                    value={value}
                                    openTo="day"
                                    views={['year', 'month', 'day']}
                                    onChange={(newValue) =>  {
                                        if (newValue){
                                            return onChange(newValue.format("YYYYMMDD"));
                                        }else{
                                            return onChange(newValue);
                                        }
                                    }}
                                    renderInput={(params) =>  <TextField fullWidth {...params} size="small"/>}
                                    sx={{width:'100%'}}
                                />
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                {/* 출장 예정자 목록 */}
                <Grid sx={modalHalfStyle}>
                    <CustomAutocomplete fullWidth
                                        name="btrrId"
                                        label="출장예정자"
                                        options={usrBtrList}
                                        value="btrrId"
                                        text="btrrNm"
                                        control={control}
                                        size="small"
                                        getValues={getValues}
                                        errors={errors.btrrId}
                    />
                </Grid>
            </Grid>
        </Grid>
    )

    useEffect(() => {
        if(!open){
            return;
        }
        reset(defaultValues);
        listReset(listDefaultValues);
        oosTypeChanged();
        //선택된 장애목록 정보 조회
        getOosHistInfo();
    }, [open]);

    //input css style
    const modalFullStyle = { width: '100%', 'textAlign': 'center', display: 'flex', mt:2, pr:1, pl:1 };
    const modalHalfStyle = { width: '50%', 'textAlign': 'center', mt:2, pr:1, pl:1};
    const modalTripleStype = { width: '33.33%', 'textAlign': 'center', mt:2, pr:1, pl:1};

    return(
    <>
        <Modal open={open}
               onClose={e=> setOpen(false)}
               aria-labelledby="parent-modal-title"
               aria-describedby="parent-modal-description"
               sx={{borderColor: "gray", maxHeight: "800px", marginTop: "10px"}}
        >
            <Box className="bigModal" sx={{bgcolor: "#f5fcff", p: 2}}
                 style={{border: "1px solid gray", borderRadius: "10px"}}
            >
                <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                    <div style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        boxShadow: "inset 0 -8px 0 #bbdefb",
                        width: "fit-content",
                        marginBottom: "15px"
                    }}>
                        <span>선택된 장애 목록</span>
                    </div>
                    <Grid className="incident_actn_table incident_table_y_scroll" style={{"maxHeight":"300px", "maxWidth": "1500px"}}>
                        <table className="incident_table_top_left" style={{ "fontSize":"12px", "width": "100%"}}>
                            <thead className="p-2" style={{"background": "rgba(228, 232, 240)"}}>
                            <tr>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "45px"}}>선택</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "45px"}}>사업연도</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "45px"}}>에너지원</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "100px"}}>사이트</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "45px"}}>RTU</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "100px"}}>장애 발생일</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "45px"}}>장애 유형</th>
                                <th className="p-2 incident_table_right_bottom" style={{"minWidth": "200px"}}>장애 상세</th>
                                <th className="p-2 incident_table_right_bottom">비고</th>
                            </tr>
                            </thead>
                            <tbody>
                            {fields.map((value, idx) => (
                                <tr key={value.oosSeq} className="text-center">
                                    <td className="p-1 incident_table_right_bottom incident_table_del" data-seq={value.oosSeq} onClick={rowDelClick}>삭제</td>
                                    <td className="p-1 incident_table_right_bottom">{value.numRowYy}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.nrnGentNm}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.siteNm}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.rtuSeq}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.oosOccuDt}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.oosTypeNm}</td>
                                    <td className="p-1 incident_table_right_bottom">{value.oosDtl}</td>
                                    <td className="p-1 incident_table_right_bottom">
                                        <CustomTextField
                                            fullWidth
                                            multiline
                                            sx={{  minWidth: "200px" }}
                                            name={`oosArray.${idx}.rmark`}
                                            label="비고"
                                            size="small"
                                            control={listControl}
                                            errors={listErrors.rmark}
                                        />
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {fields.length === 0
                            ?<div className="p-1 text-center incident_table_no_data">조회된 이력이 없습니다.</div>
                            : null
                        }
                    </Grid>
                </Grid>

                {/* 장애조치 & 출장 등록 폼 */}
                {btnType === 'actn'
                    ? <ActnForm />
                    : <BtrForm />
                }

                {/*버튼목록*/}
                <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt: 2}}>
                    <Button variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<AddCircleOutlineIcon/>}
                            onClick={handleSubmit(onSubmit)}
                    >등록</Button>
                    <Button variant="contained"
                            size="large"
                            color="primary"
                            startIcon={<CloseIcon/>}
                            sx={{ml: 2}}
                            onClick={e => setOpen(false)}
                    >닫기</Button>
                </Grid>
            </Box>


        </Modal>
        <Toast ref={toastRef} />
        <Alert ref={alertRef} />
    </>
);
}

export default IncidentMultiModal;