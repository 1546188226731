import React from 'react'
import { TextField } from '@mui/material';
import { Controller } from "react-hook-form";

const CustomTextField = (props) => {

    return (
        <Controller name={props.name} 
                    control={props.control} 
                    rules={props.rules}
                    render={({ field: { onChange, value } }) => (
                        <TextField onChange={onChange} 
                                   value={value ?? ""}
                                   error={props.errors}
                                   variant={props.variant ? props.variant : "outlined"}
                                   helperText={props.errors ? props.errors.message : ""}                                                    
                                   {...props}
                        />
                    )}                     
        />     
    )
  };
  
  export default CustomTextField