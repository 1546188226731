import React, {useEffect, useRef, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Grid from "@mui/material/Unstable_Grid2";
import axiosInstance from "../../../components/util/AxiosUtil";
import CustomAutocomplete from "../../../components/ui/CustomAutocomplete";
import CustomTextField from "../../../components/ui/CustomTextField";
import {Button, TextField} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IncidentDiagramsBtn from "../IncidentDiagramsBtn";
import Alert from "../../../components/ui/Alert";
import IncidentDtlModal from "../IncidentDtlModal";
import IncidentMultiModal from "../IncidentMultiModal";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ConstructionIcon from "@mui/icons-material/Construction";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const defaultValues = {
    pjtSeq: '',                 /* 프로젝트 일련번호 */
    sido : '',                  /* 시도 */
    sigungu : '',               /* 군구 */
    nrnGentCd : '',             /* 에너지원코드 */
    siteSeq : '',               /* 사이트 일련번호 */
    oosStsCd : '',             /* 장애 유형 코드 oosStsCd */
    resultYnNm: '',             /* 조치여부 텍스트 */
    actnStDt: null,
    actnEdDt: null,
    btrStDt: null,
    btrEdDt: null,
}

const IncidentActnSearch = ({search, checkList}) => {
    // [검색] 조회 기본세팅 - useForm
    const {
        handleSubmit : handleSubmit,
        getValues: getValues,
        control : control,
        setValue : setValue,
        formState: { errors }
    } = useForm( {defaultValues} );

    // Alert ref 객체
    const alertRef= useRef();

    // 프로젝트 목록
    const [pjtList, setPjtList] = useState([{dtlCd: "", dtlCdNm: ""}]);
    //에너지원 목록
    const [enrgList, setEnrgList] = useState([{dtlCd: "", dtlCdNm: ""}]);
    // 사이트 목록
    const [siteList, setSiteList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);
    //장애유형 목록
    const [oosTypeCdList, setOosTypeCdList] = useState([{dtlCd: "", dtlCdNm: "전체"}]);

    //조치자&출장파견자 목록
    const [usrBtrList, setUsrBtrList] = useState([{btrrId: "", btrrNm: ""}]);
    //조치결과 목록
    const [actnRsltList, setActnRsltList] = useState({dtlCd: "", dtlCdNm: ""})

    const resultYnNmList = [{label: "전체", value: ""},
                            {label: "조치", value: "조치"},
                            {label: "미조치", value: "미조치"},
                            {label: "출장예정", value: "출장예정"},
                            {label: "고장해제", value: "고장해제"}];

    //출장등록,조치등록 모달 오픈
    const [open, setOpen] = useState(false);
    //출장등록, 조치등록 멀티 모달 오픈
    const [multiOpen, setMultiOpen] = useState(false);
    //조치등록,출장등록 버튼 구분
    const [btnType, setBtnType] = useState('');

    //프로젝트 목록 조회
    const getPjtCodeList = () => {
        axiosInstance.get('/pjtCodeList').then((Response)=>{
            if (Response.request.status === 200) {
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setPjtList(Response.data.list);
            }
        });
    }

    //사이트 검색 목록 생성
    const getSiteCodeList = () => {
        axiosInstance.get('/siteCodeList', {params: {pjtSeq:getValues().pjtSeq}}).then((Response)=>{
            if (Response.request.status === 200) {
                const list  = [{dtlCd: "", dtlCdNm: "전체"}, ...Response.data.list];
                setSiteList(list);

                //site 검색 선택 정보 초기화
                setValue("siteSeq", "");
            }
        });
    }

    //에너지원 목록 조회
    const getEnrgCodeList = () => {
        axiosInstance.get('/codeList', {params: {grpCd: "NRN_GENT_CD"}}).then((Response)=>{
            if (Response.request.status === 200) {
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setEnrgList(Response.data.list);
            }
        });
    }

    //장애유형 목록 조회
    const getOosTypeCdList = () => {
        axiosInstance.get('/codeList', {params: {grpCd: "OOS_TYPE_CD"}}).then((Response)=>{
            if (Response.request.status === 200) {
                Response.data.list.unshift({dtlCd: "", dtlCdNm: "전체"});
                setOosTypeCdList(Response.data.list);
            }
        });
    }

    //출장예정자,조치자 목록 조회
    const getBtrList = () => {
        axiosInstance.get('/getBtrList').then((Response)=>{
            if (Response.request.status === 200) {
                setUsrBtrList(Response.data.list);
            }
        });
    }

    //조치결과 목록 조회
    const getActnRsltList = () => {
        axiosInstance.get('/codeList', {params: {grpCd: "ACTION_RSLT_CD"}}).then((Response)=>{
            if (Response.request.status === 200) {
                setActnRsltList(Response.data.list);
            }
        });
    }

    //프로젝트 변경 이벤트
    const pjtSeqChanged = (option) => {
        getSiteCodeList();
    }

    //조치등록&출장등록  버튼구분
    const buttonHandler = (type) => {
        if(!type) return;
        setBtnType(type);

        multiHandler();
    }

    //다중선택&단일선택 구분하여 폼 오픈
    const multiHandler = () => {
        if(checkList.length === 0){
            alertRef.current.handleOpen("확인", "조치등록/출장등록시 장애 목록에서 등록하고자 하는 장애건을 체크 해주세요.");
        }else if(checkList.length === 1){
            setOpen(true);
        }else if(checkList.length > 1){
            setMultiOpen(true);
        }
    }

    const dataLoad = (data) => {
        search(data);
    }

    //시도별 장애확인 콜백함수
    const diagramChange = (diagramForm) => {
        setValue("sido", diagramForm.sido);
        setValue("sigungu", diagramForm.sigungu);

        //초기에 조회된 에너지원 코드목록 안에 체크된 시도별장애확인 모달의 에너지원이 있을경우 값 입력
        let includesBoolean = false;
        enrgList.map((obj)=> {
            if(obj.dtlCd == diagramForm.nrnGentCd){
                includesBoolean = true;
            }
        })
        includesBoolean ? setValue("nrnGentCd", diagramForm.nrnGentCd) : setValue("nrnGentCd", "");
        dataLoad(getValues());
    }

    //장애조치현황 엑셀 다운로드
    const excelDownload = (data) => {

        axiosInstance.post('/oos/actn/excel/download', {...data}, {responseType: "blob"}).then((Response)=>{
            if (Response){

                const blob = new Blob([Response.data]);
                const fileObjectUrl = window.URL.createObjectURL(blob);
                const link = document.createElement("a");

                link.href = fileObjectUrl;
                link.style.display = "none";

                const disposition = Response.headers["content-disposition"];
                link.download = decodeURI(
                    disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/['"]/g, "")
                );

                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(fileObjectUrl);
            }
        });
    }

    useEffect(() => {
        //프로젝트 코드 목록 조회
        getPjtCodeList();
        //에너지원 코드목록 조회
        getEnrgCodeList();
        //사이트 정보 목록 조회
        getSiteCodeList();
        //장애유형 목록 조회
        getOosTypeCdList();
        //출장예정자,조치자 목록 조회
        getBtrList();
        //조치결과 목록 조회
        getActnRsltList();
    }, []);

    return(
        <Grid container
              alignItems="center"
              xs={12} sm={12} md={12} lg={12} xl={12}
              className="page-filter"
              sx={{p:0}}
        >
            {/*프로젝트명*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomAutocomplete name="pjtSeq"
                                    label="프로젝트"
                                    options={pjtList}
                                    value="dtlCd"
                                    text="dtlCdNm"
                                    control={control}
                                    getValues={getValues}
                                    errors={errors.pjtSeq}
                                    onChange={pjtSeqChanged}
                />
            </Grid>
            {/* 사이트 */}
            <Grid xs={12} sm={12} md={6} lg={4} xl={4} sx={{ p: 2 }}>
                <CustomAutocomplete name="siteSeq"
                                    label="사이트"
                                    options={siteList}
                                    value="dtlCd"
                                    text="dtlCdNm"
                                    control={control}
                                    getValues={getValues}
                                    errors={errors.siteSeq}
                />
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomTextField fullWidth
                                 name="sido"
                                 label="시도"
                                 control={control}
                />
            </Grid>
            {/* 구군 */}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomTextField fullWidth
                                 name="sigungu"
                                 label="구군"
                                 control={control}
                />
            </Grid>
            {/*에너지원*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomAutocomplete name="nrnGentCd"
                                    label="에너지원"
                                    options={enrgList}
                                    value="dtlCd"
                                    text="dtlCdNm"
                                    control={control}
                                    getValues={getValues}
                                    errors={errors.nrnGentCd}
                />
            </Grid>

            {/*장애유형*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomAutocomplete name="oosTypeCd"
                                    label="장애유형"
                                    options={oosTypeCdList}
                                    value="dtlCd"
                                    text="dtlCdNm"
                                    control={control}
                                    getValues={getValues}
                                    errors={errors.oosTypeCd}
                />
            </Grid>
            {/*조치여부*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2} sx={{ p: 2 }}>
                <CustomAutocomplete name="resultYnNm"
                                    label="조치여부"
                                    options={resultYnNmList}
                                    value="value"
                                    text="label"
                                    control={control}
                                    getValues={getValues}
                                    errors={errors.resultYnNm}
                />
            </Grid>

            <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                >
                    <Controller
                        name='btrStDt'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label={['출장 시작일']}
                                inputFormat="YYYY-MM-DD"
                                value={value}
                                openTo="day"
                                views={['year', 'month', 'day']}
                                onChange={(newValue) =>  {
                                    if (newValue){
                                        return onChange(newValue.format("YYYYMMDD"));
                                    }else{
                                        return onChange(newValue);
                                    }
                                }}
                                renderInput={(params) =>  <TextField fullWidth {...params}/>}
                                sx={{width:'100%'}}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                >
                    <Controller
                        name='btrEdDt'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label={['출장 종료일']}
                                inputFormat="YYYY-MM-DD"
                                value={value}
                                openTo="day"
                                views={['year', 'month', 'day']}
                                onChange={(newValue) =>  {
                                    if (newValue){
                                        return onChange(newValue.format("YYYYMMDD"));
                                    }else{
                                        return onChange(newValue);
                                    }
                                }}
                                renderInput={(params) =>  <TextField fullWidth {...params}/>}
                                sx={{width:'100%'}}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                >
                    <Controller
                        name='actnStDt'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label={['조치 시작일']}
                                inputFormat="YYYY-MM-DD"
                                value={value}
                                openTo="day"
                                views={['year', 'month', 'day']}
                                onChange={(newValue) =>  {
                                    if (newValue){
                                        return onChange(newValue.format("YYYYMMDD"));
                                    }else{
                                        return onChange(newValue);
                                    }
                                }}
                                renderInput={(params) =>  <TextField fullWidth {...params}/>}
                                sx={{width:'100%'}}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={3} xl={2} sx={{ p: 2 }}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ko"
                >
                    <Controller
                        name='actnEdDt'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                label={['조치 종료일']}
                                inputFormat="YYYY-MM-DD"
                                value={value}
                                openTo="day"
                                views={['year', 'month', 'day']}
                                onChange={(newValue) =>  {
                                    if (newValue){
                                        return onChange(newValue.format("YYYYMMDD"));
                                    }else{
                                        return onChange(newValue);
                                    }
                                }}
                                renderInput={(params) =>  <TextField fullWidth {...params}/>}
                                sx={{width:'100%'}}
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>


            {/* 조회 버튼*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={1.5} sx={{ p: 2 }}>
                <Button variant="contained"
                        size="large"
                        color="primary"
                        sx={{ p:2, height:'52px', width: 1}}
                        startIcon={<SearchIcon />}
                        onClick={handleSubmit(dataLoad)}
                >조회
                </Button>
            </Grid>

            {/*시도 별 장애확인 버튼*/}
            <Grid xs={12} sm={12} md={6} lg={2} xl={2.5} sx={{ p: 2 }}>
                <IncidentDiagramsBtn  name="diagram" diagramChange={diagramChange}/>
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={2} xl={1} sx={{ p: 2 }}>
                <Button variant="contained"
                        size="large"
                        color="primary"
                        sx={{ p:1, height:'52px', width:1}}
                        startIcon={<HomeRepairServiceIcon />}
                        onClick={()=> buttonHandler('btr')}
                >출장 등록</Button>
            </Grid>

            <Grid xs={12} sm={12} md={3} lg={2} xl={1} sx={{ p: 2 }}>
                <Button variant="contained"
                        size="large"
                        color="primary"
                        sx={{  p:1, height:'52px', width:1}}
                        startIcon={<ConstructionIcon />}
                        onClick={()=> buttonHandler('actn')}
                >조치 등록</Button>
            </Grid>

            {/* 엑셀 다운로드 */}
            <Grid xs={12} sm={12} md={3} lg={2} xl={2} sx={{ p: 2 }}>
                <button className="data-btn excel-btn"
                        title='장애조치 현황 Excel 다운로드'
                        style={{"margin": "2px", "padding":"3px", "width": "100%", "height": "52px"}}
                        onClick={handleSubmit(excelDownload)}
                >excel 다운로드</button>
            </Grid>

            <IncidentDtlModal open={open}
                              setOpen={setOpen}
                              btnType={btnType}
                              checkList={checkList}
                              usrBtrList={usrBtrList}
                              actnRsltList={actnRsltList}
                              oosTypeCdList={oosTypeCdList} />
            <IncidentMultiModal open={multiOpen}
                                setOpen={setMultiOpen}
                                checkList={checkList}
                                btnType={btnType}
                                usrBtrList={usrBtrList}
                                actnRsltList={actnRsltList}
                                oosTypeCdList={oosTypeCdList} />
            <Alert ref={alertRef} />
        </Grid>
    )
}

export default IncidentActnSearch;