import React from "react";

import { VarifyToken } from "./components/util/AuthUtil";
import { Routes, Route, Navigate } from "react-router-dom";

import Layout from "./components/layout/Layout";
import Login from "./content/Login";

import Main from "./content/Main";
import Dashboard from "./content/Dashboard";

import Project from "./content/Project";
import Equipment from "./content/Equipment";
import EquipmentSupport from "./content/EquipmentSupport";
import EquipmentReg from "./content/rems/support/EquipmentReg";

import Site from "./content/Site";
import Rtu from "./content/Rtu";
import Kea from "./content/Kea";

import Prdt from "./content/Prdt";

import ReqContainer from "./content/reqDocument/ReqContainer";
import Reqment from "./content/Reqment";
import Photo from "./content/photo/Photo";
import PhotoDetail from "./content/photo/PhotoDetail";
import Document from "./content/Document";
import KnrecDocument_1 from "./content/rtuInstDocument/doc/KnrecDocument_1";
import KnrecDocument_2 from "./content/rtuInstDocument/doc/KnrecDocument_2";
import LhDocument from "./content/rtuInstDocument/doc/LhDocument";
import Makr from "./content/Makr";
import Model from "./content/Model";
import IncidentDashboard from "./content/incident/dashboard/IncidentDashboard";
import IncidentSts from "./content/incident/sts/IncidentSts";
import IncidentActn from "./content/incident/actn/IncidentActn";

import Business from "./content/Business";
import MenuMg from "./content/MenuMg";
import Role from "./content/Role";
import User from "./content/User";
import CodeGrp from "./content/CodeGrp"
import CodeDtl from "./content/CodeDtl";

import MyInfo from "./content/MyInfo";

import FileDownload from "./content/FileDonwload";

import Sample2 from "./content/Sample2";
import Sample3 from "./content/Sample3";

import NotFound from "./components/error/NotFound";

import { useLocation } from 'react-router-dom';

function Router() {
    
    const AuthRoute = ({component}) => { return VarifyToken(useLocation()) ? component : <Navigate to="/login" />; }
    const LoginHandler = ({component}) => { return VarifyToken() ? <Navigate to="/"/> : component; } 

    return (
        <Routes>

            {/*로그인*/}
            <Route path="login" element={<LoginHandler component={<Login />}/>} />

            {/*영업지원 / 에너지관리공단 사진 대지(사진3장) 문서*/}
            <Route path="sales/document/knrecDocument_1" element={<KnrecDocument_1 />} />
            {/*영업지원 / 에너지관리공단 사진 대지(사진5장) 문서*/}
            <Route path="sales/document/knrecDocument_2" element={<KnrecDocument_2 />}/>

            {/*영업지원 / LH 연동시험신청서 문서*/}
            <Route path="sales/document/lhDocument" element={<LhDocument />}/>

            {/*파일다운로드*/}
            <Route path="fileDownload" element={<FileDownload />} />

            <Route path="/" element={<Layout />} >

                {/*메인*/}
                <Route path="" element={<AuthRoute component={<Dashboard />}/>} />

                {/*제품관리 / 제품 관리*/}
                <Route path="product/prdt" element={<AuthRoute component={<Prdt />}/>} />
                {/*제품관리 / eTU 사진등록*/}
                <Route path="product/photo" element={<AuthRoute component={<Photo />}/>} />
                {/*제품관리 / eTU 사진등록관리*/}
                <Route path="product/photo/detail" element={<PhotoDetail/>} />

                
                {/*신재생서비스 / 사이트 관리*/}
                <Route path="rems/site" element={<AuthRoute component={<Site />}/>} />                
                {/*신재생서비스 / RTU 관리*/}
                <Route path="rems/rtu" element={<AuthRoute component={<Rtu />}/>} />                
                {/*신재생서비스 / 공단계정 관리*/}
                <Route path="rems/kea" element={<AuthRoute component={<Kea />}/>} />                
                {/*신재생서비스 / 설비 조회*/}
                <Route path="rems/equipment" element={<AuthRoute component={<Equipment />}/>} />                
                {/*신재생서비스 / 설비 등록지원*/}
                <Route path="rems/support" element={<AuthRoute component={<EquipmentSupport />}/>} />                
                {/*신재생서비스 / 설비 등록지원 / 설비 등록*/}
                <Route path="rems/support/pv" element={<EquipmentReg />}/>
                {/*신재생서비스 / 제조사 관리*/}
                <Route path="rems/makr" element={<AuthRoute component={<Makr />}/>} />                                                
                {/*신재생서비스 / 모델 관리*/}
                <Route path="rems/model" element={<AuthRoute component={<Model />}/>} />                                                

                {/*영업지원 / 프로젝트 관리*/}
                <Route path="sales/project" element={<AuthRoute component={<Project />}/>} />
                {/*영업지원 / 업무요청서 등록*/}
                <Route path="sales/request" element={<AuthRoute component={<ReqContainer />}/>} />
                {/*영업지원 / 업무요청서 관리*/}
                <Route path="sales/work" element={<AuthRoute component={<Reqment />}/>} />                
                {/*영업지원 / 문서 관리*/}
                <Route path="sales/document" element={<AuthRoute component={<Document />}/>} />
                {/*영업지원 / 장애현황 */}
                <Route path="sales/incident/dashboard" element={<AuthRoute component={<IncidentDashboard />}/>} />
                {/*영업지원 / 장애현황 */}
                <Route path="sales/incident/sts" element={<AuthRoute component={<IncidentSts />}/>} />
                {/*영업지원 / 장애조치 */}
                <Route path="sales/incident/actn" element={<AuthRoute component={<IncidentActn />}/>} />
                

                {/*시스템 관리 / 업체 관리*/}
                <Route path="system/business" element={<AuthRoute component={<Business />}/>} />
                {/*시스템 관리 / 메뉴 관리*/}
                <Route path="system/menu" element={<AuthRoute component={<MenuMg />}/>} />
                {/*시스템 관리 / 권한 관리*/}
                <Route path="system/role" element={<AuthRoute component={<Role />}/>} />
                {/*시스템 관리 / 사용자 관리*/}
                <Route path="system/user" element={<AuthRoute component={<User />}/>} />
                {/*시스템 관리 / 코드그룹 관리*/}
                <Route path="/system/code/group" element={<AuthRoute component={<CodeGrp />}/>} /> 
                {/*시스템 관리 / 코드 관리(상세)*/}
                <Route path="/system/code" element={<AuthRoute component={<CodeDtl />}/>} />                                
                {/*시스템 관리 / 개인정보 관리*/}
                <Route path="system/myInfo" element={<AuthRoute component={<MyInfo />}/>} />                                
                
                {/*Sample*/}
                <Route path="sample1" element={<AuthRoute component={<Sample3 />}/>} />      
                {/*Sample2*/}
                <Route path="sample2" element={<AuthRoute component={<Sample2 />}/>} />      

            </Route>

            <Route path="/*" element={<NotFound/>}/>
        </Routes>   
    );
}

export default Router;