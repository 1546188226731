import {Modal, Box, Button, TextField} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import axiosInstance from "../../components/util/AxiosUtil";
import {Controller, useForm} from "react-hook-form";
import CustomTextField from "../../components/ui/CustomTextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import CustomAutocomplete from "../../components/ui/CustomAutocomplete";
import Alert from "../../components/ui/Alert";
import Toast from "../../components/ui/Toast";
import {viewModeType} from "../../components/const/CommCode";

const defaultValues = {
    pjtNm: '',              //프로젝트 명
    sido: '',               //시도
    sigungu: '',            //시군구
    siteSeq: '',            //사이트 일련번호
    rtuSeq: '',             //rtu일련번호
    rmark: '',              //비고
    siteNm: '',             //사이트 명
    nrnGentCd: '',          //에너지원 코드
    nrnGentNm: '',          //에너지원 코드 명
    cntrctrNm: '',          //시공업체
    cntrctrTlno: '',        //시공업체 전화번호
    connTypeCd: '',         //연동 방법 코드
    connTypeNm: '',         //연동 방법 코드 명
    loraId: '',             //로라 아이디
    adr: '',                //주소
    oosDtl: '',             //고장상세
    oosOccuDt: '',          //고장 발생시간
    oosTypeCd: '',          //고장 유형 코드
    oosTypeNm: '',          //고장 유형 코드 명
    btrPamDate: '',         //출장 예정일
    btrrId: '',             //출장자 아이디
    btrrNm: '',             //출장자 이름
    actnDt: '',             //조치일자
    actnr: '',              //조치자
    actnRsltCd: ''          //조치 결과 코드
}

//단일 상세 폼
const IncidentDtlModal = ({open, setOpen, btnType, checkList, viewMode, usrBtrList, actnRsltList, oosTypeCdList}) => {

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        formState: { errors }
    } = useForm( {defaultValues} );

    //장애조치 이력
    const [oosActnList, setOosActnList] = useState([{actnDt: "", oosTypeNm: "", actnr: "", actnRsltNm: "", rmark: ""}]);
    //조치결과 목록 원본
    const [actnChangeList, setActnChangeList] = useState([]);

    // Alert ref 객체
    const alertRef= useRef();
    //toast ref 객체
    const toastRef = useRef();

    const getOosHistInfo = () => {
        //모달 초기화
        reset(defaultValues);

        const oosSeq = checkList[0];
        axiosInstance.get('/getOosDtlInfo', {params: {oosSeq:oosSeq}}).then((Response) => {
            if(Response.request.status === 200) {

                //null이 들어가있을경우 가끔 오류생김 방지
                let obj  = Response.data.info;
                for(const key in obj){
                    if(!obj[key]) obj[key] = '';
                }
                //날짜 컴포넌트 들이 빈값이 들어갈 경우 빨간 테두리로 표현되어 null로 치환
                if(btnType === 'actn'){
                    obj.btrPamDate = obj.btrPamDate ? obj.btrPamDate : null;
                    obj.actnDt = obj.actnDt ? obj.actnDt : null;
                }else{
                    obj.btrPamDate = null;
                    obj.actnDt = null;
                }

                reset(obj);
                oosTypeChanged();
                setOosActnList(Response.data.list);
                console.log(Response.data.info);
            }
        })
    }

    //장애유형 변경 이벤트
    const oosTypeChanged = () => {
        const defaultList = actnRsltList;
        if(!getValues().oosTypeCd){
            setActnChangeList(defaultList);
        }else{
            const list = defaultList.filter(obj => {
                return getValues().oosTypeCd === obj.upDtlCd;
            })
            setActnChangeList(list);
        }
    }

    //장애조치 등록 폼
    const ActnForm = () => {
        return (
            <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                <div style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    boxShadow: "inset 0 -8px 0 #bbdefb",
                    width: "fit-content",
                    marginBottom: "15px"
                }}>
                    <span>장애 조치</span>
                </div>
                <Grid container spacing={1}>
                    <Grid sx={modalTripleStype}>
                        <CustomTextField
                            fullWidth
                            sx={{width:'100%', backgroundColor: '#f7f7f7'}}
                            name="btrPamDate"
                            label="출장일정"
                            size="small"
                            control={control}
                            errors={errors.btrPamDate}
                            InputProps={{ readOnly: true, }}
                        />
                    </Grid>

                    {/* 장애 조치일 */}
                    <Grid sx={modalTripleStype}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="ko"
                        >
                            <Controller
                                name='actnDt'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label={['조치일자']}
                                        inputFormat="YYYY-MM-DD"
                                        value={value}
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        onChange={(newValue) =>  {
                                            if (newValue){
                                                return onChange(newValue.format("YYYYMMDD"));
                                            }else{
                                                return onChange(newValue);
                                            }
                                        }}
                                        renderInput={(params) =>  <TextField fullWidth {...params} size="small"/>}
                                        sx={{width:'100%'}}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                    {/* 조치자 목록 */}
                    <Grid sx={modalTripleStype}>
                        <CustomAutocomplete fullWidth
                                            name="actnr"
                                            label="조치자"
                                            options={usrBtrList}
                                            value="btrrId"
                                            text="btrrNm"
                                            control={control}
                                            size="small"
                                            getValues={getValues}
                                            errors={errors.actnr}
                        />
                    </Grid>

                    {/* 장애유형 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="oosTypeCd"
                                            label="장애유형"
                                            options={oosTypeCdList}
                                            value="dtlCd"
                                            text="dtlCdNm"
                                            control={control}
                                            size="small"
                                            getValues={getValues}
                                            errors={errors.oosTypeCd}
                                            onChange={oosTypeChanged}
                        />
                    </Grid>

                    {/* 조치결과 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="actnRsltCd"
                                            label="조치결과"
                                            options={actnChangeList}
                                            value="dtlCd"
                                            text="dtlCdNm"
                                            control={control}
                                            size="small"
                                            getValues={getValues}
                                            errors={errors.actnRsltCd}
                        />
                    </Grid>

                    <Grid sx={modalFullStyle}>
                        <CustomTextField
                            fullWidth
                            multiline
                            rows={'2'}
                            sx={{width:'100%'}}
                            name="rmark"
                            label="비고"
                            size="small"
                            control={control}
                            errors={errors.rmark}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    //출장등록 폼
    const BtrForm = () => {
        return(
            <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                <div style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    boxShadow: "inset 0 -8px 0 #bbdefb",
                    width: "fit-content",
                    marginBottom: "15px"
                }}>
                    <span>출장 등록</span>
                </div>
                <Grid container spacing={1}>
                    {/* 장애 조치일 */}
                    <Grid sx={modalHalfStyle}>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="ko"
                        >
                            <Controller
                                name='btrPamDate'
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <DatePicker
                                        label={['출장예정일']}
                                        inputFormat="YYYY-MM-DD"
                                        value={value}
                                        openTo="day"
                                        views={['year', 'month', 'day']}
                                        onChange={(newValue) =>  {
                                            if (newValue){
                                                return onChange(newValue.format("YYYYMMDD"));
                                            }else{
                                                return onChange(newValue);
                                            }
                                        }}
                                        renderInput={(params) =>  <TextField fullWidth {...params} size="small"/>}
                                        sx={{width:'100%'}}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>

                    {/* 출장예정자 */}
                    <Grid sx={modalHalfStyle}>
                        <CustomAutocomplete fullWidth
                                            name="btrrId"
                                            label="출장예정자"
                                            options={usrBtrList}
                                            value="btrrId"
                                            text="btrrNm"
                                            control={control}
                                            size="small"
                                            getValues={getValues}
                                            errors={errors.btrrId}
                        />
                    </Grid>
                    {/*비고*/}
                    <Grid sx={modalFullStyle}>
                        <CustomTextField
                            fullWidth
                            multiline
                            rows={'2'}
                            sx={{width:'100%'}}
                            name="rmark"
                            label="비고"
                            size="small"
                            control={control}
                            errors={errors.rmark}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    const onSubmit = (data) => {
        if(btnType === 'actn'){
            actnInsert(data);
        }else if(btnType === 'btr'){
            btrInsert(data);
        }
    }

    //출장 등록
    const btrInsert = (data) => {
        if(!data.btrPamDate){
            alertRef.current.handleOpen("확인", "출장예정일은 필수 항목입니다.");
            return;
        }
        if(!data.btrrId){
            alertRef.current.handleOpen("확인", "출장예정자는 필수 항목입니다.");
            return;
        }
        axiosInstance.post('/oosBtrInfo', {...data}).then((Response)=>{
            if(Response.request.status === 200 ){

                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "저장 되었습니다.");
                    setOpen(false);

                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage );
                }
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");
            }
        });
    }

    //조치 등록
    const actnInsert = (data) => {
        console.log(data);
        if(!data.actnr){
            alertRef.current.handleOpen("확인", "조치자 입력은 필수입니다.");
            return;
        }
        if(!data.actnDt){
            alertRef.current.handleOpen("확인", "조치일자 입력은 필수입니다.");
            return;
        }


        axiosInstance.post('/oosActnInfo', {...data}).then((Response)=>{
            if(Response.request.status === 200 ){

                if(Response.data.resultCode === "SUCCESS"){
                    toastRef.current.handleOpen("success", "저장 되었습니다.");
                    setOpen(false);

                }else if(Response.data.resultCode === "ERROR"){
                    toastRef.current.handleOpen("error", Response.data.resultMessage );
                }
            }else{
                toastRef.current.handleOpen("error", "오류 입니다. 데이터를 저장 할 수 없습니다.");
            }
        });
    }

    useEffect(() => {
        if(!open){
            return;
        }
        getOosHistInfo();
    }, [open]);

    //input css style
    const modalFullStyle = { width: '100%', 'textAlign': 'center', display: 'flex', mt:2, pr:1, pl:1 };
    const modalHalfStyle = { width: '50%', 'textAlign': 'center', mt:2, pr:1, pl:1};
    const modalTripleStype = { width: '33.33%', 'textAlign': 'center', mt:2, pr:1, pl:1};

    return(
        <>
            <Modal open={open}
                   onClose={e => setOpen(false)}
                   aria-labelledby="parent-modal-title"
                   aria-describedby="parent-modal-description"
                   sx={{borderColor: "gray"}}
                   className="inc_diagram_modal"
            >
                <Box className="Modal" sx={{bgcolor: "#f5fcff", p: 2}}
                     style={{border: "1px solid gray", borderRadius: "10px"}}>
                    {/*<h3 id="parent-modal-title" className="limiter">상세정보</h3>*/}

                    <Grid sx={{p: 2, mt: 1, bgcolor: "#f7f7f7", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                        <div style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            boxShadow: "inset 0 -8px 0 #bbdefb",
                            width: "fit-content",
                            marginBottom: "15px"
                        }}>
                            <span>상세정보</span>
                        </div>
                            <Grid container spacing={1}>
                                {/* RTU 일련번호 */}
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="pjtNm"
                                        label="프로젝트명"
                                        size="small"
                                        control={control}
                                        errors={errors.pjtCdNm}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="rtuSeq"
                                        label="RTU일련번호"
                                        size="small"
                                        control={control}
                                        errors={errors.rtuSeq}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="nrnGentNm"
                                        label="에너지원"
                                        size="small"
                                        control={control}
                                        errors={errors.nrnGentNm}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="sido"
                                        label="시도"
                                        size="small"
                                        control={control}
                                        errors={errors.sido}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="sigungu"
                                        label="구군"
                                        size="small"
                                        control={control}
                                        errors={errors.sigungu}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="siteSeq"
                                        label="rems아이디"
                                        size="small"
                                        control={control}
                                        errors={errors.siteSeq}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="siteNm"
                                        label="사이트명"
                                        size="small"
                                        control={control}
                                        errors={errors.siteNm}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="cntrctrNm"
                                        label="시공사"
                                        size="small"
                                        control={control}
                                        errors={errors.cntrctrNm}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalTripleStype}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="cntrctrTlno"
                                        label="시공업체전화번호"
                                        size="small"
                                        control={control}
                                        errors={errors.cntrctrTlno}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalHalfStyle}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="connTypeNm"
                                        label="연동방법"
                                        size="small"
                                        control={control}
                                        errors={errors.connTypeNm}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalHalfStyle}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="loraId"
                                        label="로라아이디"
                                        size="small"
                                        control={control}
                                        errors={errors.loraId}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalFullStyle}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="adr"
                                        label="주소"
                                        size="small"
                                        control={control}
                                        errors={errors.adr}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalFullStyle}>
                                    <CustomTextField
                                        fullWidth
                                        multiline
                                        rows={'2'}
                                        sx={{width:'100%'}}
                                        name="oosDtl"
                                        label="장애상세"
                                        size="small"
                                        control={control}
                                        errors={errors.oosDtl}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalHalfStyle}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="oosOccuDt"
                                        label="장애발생일"
                                        size="small"
                                        control={control}
                                        errors={errors.oosOccuDt}
                                        InputProps={{ readOnly: true, }}
                                    />
                                </Grid>
                                <Grid sx={modalHalfStyle}>
                                    <CustomTextField
                                        fullWidth
                                        sx={{width:'100%'}}
                                        name="oosTypeNm"
                                        label="장애유형"
                                        size="small"
                                        control={control}
                                        errors={errors.oosTypeNm}
                                        InputProps={{ readOnly: true }}
                                    />
                                </Grid>
                                {/*<Grid sx={modalTripleStype}>*/}
                                {/*    <CustomTextField*/}
                                {/*        fullWidth*/}
                                {/*        sx={{width:'100%'}}*/}
                                {/*        name="lastBtrPamDate"*/}
                                {/*        label="출장일정"*/}
                                {/*        size="small"*/}
                                {/*        control={control}*/}
                                {/*        errors={errors.lastBtrPamDate}*/}
                                {/*        InputProps={{ readOnly: true, }}*/}
                                {/*    />*/}
                                {/*</Grid>*/}
                            </Grid>
                    </Grid>

                    <Grid sx={{p: 2, mt: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                        <div style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            boxShadow: "inset 0 -8px 0 #bbdefb",
                            width: "fit-content",
                            marginBottom: "15px"
                        }}>
                            <span>장애 조치 이력</span>
                        </div>
                        <Grid className="incident_actn_table">
                            <table className="incident_table_top_left" style={{"width": "100%"}}>
                                <thead className="p-2" style={{"width": "100%", "background": "rgba(228, 232, 240)"}}>
                                <tr>
                                    <th className="p-2 incident_table_right_bottom">차수</th>
                                    <th className="p-2 incident_table_right_bottom">조치일</th>
                                    <th className="p-2 incident_table_right_bottom">장애유형</th>
                                    <th className="p-2 incident_table_right_bottom">조치자</th>
                                    <th className="p-2 incident_table_right_bottom">조치결과</th>
                                    <th className="p-2 incident_table_right_bottom">비고</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {oosActnList.map((value, idx) => (
                                        <tr key={idx} className="text-center">
                                            <td className="p-1 incident_table_right_bottom">{idx + 1}</td>
                                            <td className="p-1 incident_table_right_bottom">{value.actnDt}</td>
                                            <td className="p-1 incident_table_right_bottom">{value.oosTypeNm}</td>
                                            <td className="p-1 incident_table_right_bottom">{value.actnr}</td>
                                            <td className="p-1 incident_table_right_bottom">{value.actnRsltNm}</td>
                                            <td className="p-1 incident_table_right_bottom">{value.rmark}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {oosActnList.length === 0
                                ?<div className="p-1 text-center incident_table_no_data">조회된 이력이 없습니다.</div>
                                : null
                            }
                        </Grid>
                    </Grid>

                    {/* 장애조치 & 출장 등록 폼 */}
                    {btnType === 'actn'
                        ? <ActnForm />
                        : <BtrForm />
                    }

                    {/*버튼목록*/}
                    <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt: 2}}>
                        <Button variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<AddCircleOutlineIcon/>}
                                onClick={handleSubmit(onSubmit)}
                        >등록</Button>
                        <Button variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<CloseIcon/>}
                                sx={{ml: 2}}
                                onClick={e => setOpen(false)}
                        >닫기</Button>
                    </Grid>
                </Box>
            </Modal>
            <Toast ref={toastRef} />
            <Alert ref={alertRef} />
        </>
    )
}

export default IncidentDtlModal;