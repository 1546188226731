import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

const CustomMultipleAutocomplete = (props) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                    size={props.size}
                    options={props.options}
                    disabled={props.disabled}
                    multiple // 다중 선택 활성화
                    getOptionLabel={(option) => (option ? option[props.text] : '')}
                    onChange={(_, options) => {
                        // 다중 선택 시 options는 배열
                        onChange(options.map((option) => option[props.value]));
                        if (props.onChange) {
                            props.onChange(options); // 부모 컴포넌트에서 다중 선택 값 사용 가능
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option[props.value] === value[props.value]
                    }
                    value={
                        props.options.filter((option) =>
                            // 선택된 값들 확인
                            (props.getValues(props.name) || []).includes(option[props.value])
                        )
                    }
                    sx={{ width: '100%' }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            {...field}
                            inputRef={ref}
                            label={props.label}
                            variant={props.variant ? props.variant : 'outlined'}
                            error={props.errors}
                            helperText={props.errors ? props.errors.message : ''}
                        />
                    )}
                />
            )}
        />
    );
};

export default CustomMultipleAutocomplete;