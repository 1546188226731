import React, { Component, useRef } from 'react'
import  { getHttpAuthHeader } from '../../../components/util/AuthUtil';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import '../../../assets/css/DataTable.css';

// Korean
const lang_kor = {
    "decimal" : "",
    "emptyTable" : "데이터가 없습니다.",
    "info" : "_START_ - _END_ (총 _TOTAL_)",
    "infoEmpty" : "0",
    "infoFiltered" : "(전체 _MAX_ 명 중 검색결과)",
    "infoPostFix" : "",
    "thousands" : ",",
    "lengthMenu" : "_MENU_ 개씩 보기",
    "loadingRecords" : "로딩중...",
    "processing" : "",
    "search" : "검색 : ",
    "zeroRecords" : "검색된 데이터가 없습니다.",
    "paginate" : {
        "first" : "첫 페이지",
        "last" : "마지막 페이지",
        "next" : "다음",
        "previous" : "이전"
    },
    "aria" : {
        "sortAscending" : " :  오름차순 정렬",
        "sortDescending" : " :  내림차순 정렬"
    }
};

class IncidentTable extends Component {

    constructor(props){

        super(props);

        this.state = {
            table: null,
            pageLength: 10
        }

        this.handleClick = this.handleClick.bind(this);

        this.getFilterData = this.getFilterData.bind(this);

    }

    //버튼 클릭 이벤트
    handleClick(e) {
        this.props.cellClick(e);
    }

    getFilterData(data){
        const datatableFilter = JSON.parse($('#datatable-filter-' + this.props.id).val());
        return $.extend(data, datatableFilter);
    }

    componentDidMount(){

        this.setState({
            pageLength: Number(this.props.pageLength)
        });

        //에너지원 전체장애 콜백
        const getNrnTotal = (cnt) =>{
            this.props.getNrnTotal(cnt);
        }

        this.state.table = $('#' + this.props.id).DataTable({
            ajax: {
                type: 'GET',
                url: `${process.env.REACT_APP_API_URL}` + this.props.url,
                dataType: 'json',
                data: this.getFilterData,
                beforeSend(request) {
                    request.setRequestHeader('Authorization', getHttpAuthHeader());
                },
                dataSrc(data) {
                    //console.log(data.totalCnt);
                    getNrnTotal(data.totalCnt);
                    return data.list;
                }
            },
            responsive: {
                details: false
            },
            processing: true,
            columns: this.props.columns,
            columnDefs: this.props.columnDefs,
            language : lang_kor,
            serverSide : false,
            scrollX: false,
            searching: false,
            lengthChange: false,
            info: false,
            select: true,
            paging : this.props.paging,
            pageLength: this.props.pageLength,
            destroy: false,
            ordering: false
        })

        this.handleClick = this.state.table.on('click', 'div', this.handleClick);
    }

    componentDidUpdate(prevProps){

        if (this.props.filter !== prevProps.filter){

            this.state.table.ajax.params({...this.props.filter});

            this.state.table.ajax.reload(null, this.props.pageFixed);
        }
    }

    componentWillUnmount(){

        if (this.state.table){
            this.state.table.destroy();
        }
    }

    render() {

        return (
            <div style={{ width: this.props.width }}>
                <table id={ this.props.id }  style={{ width: this.props.width }}>
                    <thead>
                    <tr>
                        {
                            this.props.columns.map((column, index) => (
                                <th key={index} style={{fontSize: "13px", padding: "5px"}}>{column.html ? column.html : column.title}</th>
                            ))
                        }
                    </tr>
                    </thead>
                </table>
                <input id={"datatable-filter-" + this.props.id} type="hidden" value={JSON.stringify(this.props.filter)}></input>
            </div>
        )
    }
}

export default IncidentTable;