import {Box, Button, Divider, Modal} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from '@mui/icons-material/Search';
import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useRef, useState} from "react";
import axiosInstance from "../../components/util/AxiosUtil";
import LayersIcon from "@mui/icons-material/Layers";
import IncidentTable from "./sts/IncidentTable";

const defaultValues = {sido: "", sigungu: "", oosStsCd: ""}

const IncidentDiagramsBtn = (props) => {

    /** 시도 관련 */
    const [sidoList, setSidoList] = useState([{sido: "", incCnt: 0, active: false}]);//시도목록
    const [sidoTotal, setSidoTotal] = useState(0)//시도 전체 장애

    /** 군구 관련 */
    const [sigunguExpanded, setSigunguExpanded] = useState(false); //구군 정보 펼침 유무
    const [sigunguTotal, setSigunguTotal] = useState(0)//구군 전체 장애
    const [sigunguList, setSigunguList] = useState([{sido: "", sigungu: "", incCnt: 0, active: false}]);

    const [open, setOpen] = useState(false);
    const [nrnTotal, setNrnTotal] = useState(0)//에너지원 전체 장애

    const [activeColumnIdx, setActiveColumnIdx] = useState('0');

    let modalCheckVal = useRef({sido: "", sigungu: ""});
    // 검색필터
    const [filterData, setFilterData] = useState( {defaultValues} );

    const getSidoList = () => {
        axiosInstance.get('/sidoList').then((Response) => {
            if(Response.request.status === 200) {
                const list = Response.data.list;
                if(!list){
                    return;
                }
                setSidoTotal(list[0].totalCnt);
                list.map((row) => {
                    row.active =  false;
                })

                setSidoList(list);
            }
        })
    }

    const sidoBtnClickEvt = (el, idx) => {
        if (!open) return;
        let obj = {sido: "", sigungu: ""};

        let updatedList = [...sidoList];
        updatedList.map((row, index) => {
           if(idx === index){
               row.active = !row.active;
               if(row.active){
                   obj.sido = el.sido;
               }
               modalCheckVal = obj;

               dataLoad(obj);

               setSigunguExpanded(row.active);
           }else{
               row.active = false;
           }
        })
        setSidoList(updatedList);

        getSigunguList();

    }

    // 구군 목록 조회
    const getSigunguList = () => {
        if(!modalCheckVal.sido){
            return;
        }

        axiosInstance.get('/sigunguList', {params: {sido: modalCheckVal.sido}}).then((Response) => {
            if(Response.request.status === 200) {
                const list = Response.data.list;
                if(!list) return;
                setSigunguTotal(list[0].totalCnt);
                list.map((row) => {
                    row.active =  false;
                })
                setSigunguList(list);

            }
        })
    }

    const sigunguBtnClickEvt = (el, idx) => {
        if(!open) return;
        let obj = {sido: el.sido, sigungu: ""};

        let updateList = [...sigunguList];
        updateList.map((row, index) => {
            if(idx === index){
                row.active = !row.active;
                if(row.active){
                    obj.sigungu = el.sigungu;
                }
                modalCheckVal = obj;

                dataLoad(obj);
            }else{
                row.active = false;
            }
        })
        setSigunguList(updateList);

    }

    //테이블 컬럼 정보
    const columns = [
        { id: "oosStsNm", data: "oosStsNm", title: '' ,width: '16%', className: "text-center p-1", responsivePriority: 0 },
        { id: "totalCnt", data: "totalCnt", title: '전체', width: '12%', className: "incident_table table_col_1 text-center", responsivePriority: 1},
        { id: "nrn100", data: "nrn100", title: '태양광', width: '12%', className: "incident_table table_col_2 text-center", responsivePriority: 2 },
        { id: "nrn200", data: "nrn200", title: '지열', width:'12%', className: "incident_table table_col_3 text-center", responsivePriority: 3 },
        { id: "nrn300", data: "nrn300", title: '태양열', width:'12%', className: "incident_table table_col_4 text-center", responsivePriority: 4 },
        { id: "nrn310", data: "nrn310", title: '태양열(자연)', width:'12%', className: "incident_table table_col_5 text-center", responsivePriority: 5 },
        { id: "nrn400", data: "nrn400", title: '연료전지', width:'12%', className: "incident_table table_col_6 text-center", responsivePriority: 6 },
        { id: "nrn500", data: "nrn500", title: '기타', width:'12%', className: "incident_table table_col_7 text-center", responsivePriority: 7 }
    ]

    //datatable load
    function dataLoad(data){
        setFilterData(data);
    }

    const columnDefs = [
        {
            className: "text-center p-0",
            "targets":'_all',
            render: function(data, type, row, meta) {
                let result = "<div class='incident_style table_col_"+meta.col+"' data-colindex='"+meta.col+"' data-oosstscd='"+row.oosStsCd+"'>"+ data + "</div>";
                return result;
            }
        }
    ]

    const cellClickFunc = (e) => {

        const colIdx = e.target.dataset.colindex;

        if(!colIdx){
            return;
        }
        if(colIdx === '0'){
            return;
        }else{
            //현재 선택되어 있는 컬럼 색상초기화
            const allActive = document.querySelectorAll('.activeColumn');
            allActive.forEach(element => {
                element.classList.remove('activeColumn');
            });

            const className = "table_col_" + colIdx;
            const elements = document.querySelectorAll(`.${className}`);

            let obj = filterData;

            if(activeColumnIdx == colIdx){
                setActiveColumnIdx('0');
            }else{
                setActiveColumnIdx(colIdx);
                elements.forEach(element => {
                    element.classList.add('activeColumn');
                });
            }
            obj.nrnGentCd = nrnSettingFunc(colIdx);
            setFilterData(obj);
        }
    }

    const nrnSettingFunc = (idx) => {

        let result = "";

        if(idx == '0' || idx == '1'){
            result = "0";
            return result;
        }
        columns.map((e, index)=> {
            if(idx == index){
                let nrnCd = e.id;
                const nrnGentCd = nrnCd.match(/\d+/);
                result = nrnGentCd[0];
            }
        })
        return result;
    }

    const diagramChange = () => {
        if(props.diagramChange){
            props.diagramChange(filterData);
        }
        setOpen(false);
    }

    //에너지원 전체장애 콜백
    const getNrnTotal = (cnt) => {
        setNrnTotal(cnt);
    }

    useEffect(() => {
        getSidoList();
    }, []);

    //에너지원 테이블 클릭 상태 제거
    useEffect(() => {
        setActiveColumnIdx('0');
        const elements = document.querySelectorAll('.activeColumn');
        elements.forEach(element => {
            element.classList.remove('activeColumn');
        });

    }, [filterData])

    return (
        <>
            {/*장애 버튼*/}
            <Button variant="contained"
                    size="large"
                    color="primary"
                    sx={{p: 2, height: '52px', width: 1}}
                    startIcon={<LayersIcon/>}
                    onClick={() => {
                        setOpen(true);
                    }}
            >시도별 장애 확인</Button>

            {/* 시도별 장애확인 모달 */}
            <Modal open={open}
                   onClose={e => setOpen(false)}
                   aria-labelledby="parent-modal-title"
                   aria-describedby="parent-modal-description"
                   sx={{borderColor: "gray"}}
                   className="inc_diagram_modal"
            >
                <Box className="Modal" sx={{bgcolor: "#f5fcff", p: 2}}
                     style={{border: "1px solid gray", borderRadius: "10px"}}>
                    <h3 id="parent-modal-title" className="limiter">시도별 장애확인</h3>

                    <Grid sx={{p: 2, mt: 1, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                        <div style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                            boxShadow: "inset 0 -8px 0 #bbdefb",
                            width: "fit-content",
                            marginBottom: "15px"
                        }}>
                            <span style={{marginRight: "20px", color: "red"}}>시도</span>
                            <span style={{marginRight: "10px"}}>전체 장애</span>
                            <span>{sidoTotal}</span>
                            <span>건</span>
                        </div>

                        <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "space-evenly"
                        }}>
                            {sidoList.length > 0 ?
                                sidoList.map((el, idx) => (
                                <button key={idx}
                                        className={`incident_sido_chip_style ${el.active ? "active" : ""}`}
                                        onClick={() => sidoBtnClickEvt(el, idx)}>
                                    <span className="incident_sido_long_text"
                                            style={{fontSize: "14px", fontWeight: "bold"}}
                                    >{el.sido}</span>
                                    <span><b>{el.incCnt}</b>건</span>
                                </button>
                            ))
                            :null
                            }
                        </div>
                    </Grid>

                    {/*시군구 chip*/}
                    <div style={{marginTop: "10px"}}>
                        <div className={`sigungu_accordion ${sigunguExpanded ? 'open' : ''}`}>
                            <div style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                boxShadow: "inset 0 -8px 0 #bbdefb",
                                width: "fit-content",
                                marginBottom: "15px"
                            }}>
                                <span style={{marginRight: "20px", color: "red"}}>구군</span>
                                <span style={{marginRight: "10px"}}>전체 장애</span>
                                <span>{sigunguTotal}</span>
                                <span>건</span>
                            </div>
                            <div style={{
                                overflowY: "auto",
                                maxHeight: "135px",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-evenly",
                                overflowX: "hidden"
                            }}>
                                {sigunguList.length > 0 ?
                                    sigunguList.map((el, idx) => (
                                        <button key={idx}
                                                className={`incident_sigungu_chip_style ${el.active ? "active" : ""}`}
                                                onClick={() => sigunguBtnClickEvt(el, idx)}>
                                            <span style={{fontSize: "14px", fontWeight: "bold"}}>{el.sigungu}</span>
                                            <span><b>{el.incCnt}</b>건</span>
                                        </button>
                                    ))
                                :null}
                            </div>
                        </div>
                    </div>

                    {/*에너지 종류별 건수 테이블*/}
                    <div style={{marginTop: "10px"}}>
                        <Grid sx={{p: 2, bgcolor: "#fff", borderRadius: "15px", border: "1px solid #e0e0e0"}}>
                            <div style={{
                                fontSize: "15px",
                                fontWeight: "bold",
                                boxShadow: "inset 0 -8px 0 #bbdefb",
                                width: "fit-content",
                                marginBottom: "15px"
                            }}>
                                <span style={{marginRight: "20px", color: "red"}}>에너지원</span>
                                <span style={{marginRight: "10px"}}>전체 장애</span>
                                <span>{nrnTotal}</span>
                                <span>건</span>
                            </div>

                            <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                                <IncidentTable id="incident_table_1"                  //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                                 width="100%"                             //테이블 너비
                                                 url="/oosNrnIncList"                    //ajax url
                                                 columns={columns}                        //테이블 컬럼 정의
                                                 columnDefs={columnDefs}                  //Custom 컬럼 정의
                                                 filter={filterData}                      //ajax 검색 필터 정의
                                                 paging={false}                            //페이징 여부
                                                 cellClick={cellClickFunc}                //버튼 클릭 콜백함수
                                               getNrnTotal={getNrnTotal}
                                />
                            </Grid>
                        </Grid>
                    </div>

                    {/*버튼목록*/}
                    <Grid sx={{display: 'flex', width: '100%', justifyContent: 'flex-end', mt: 2}}>
                        <Button variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<SearchIcon/>}
                                onClick={diagramChange}
                        >조회</Button>
                        <Button variant="contained"
                                size="large"
                                color="primary"
                                startIcon={<CloseIcon/>}
                                sx={{ml: 2}}
                                onClick={e => setOpen(false)}
                        >닫기</Button>
                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

export default IncidentDiagramsBtn;