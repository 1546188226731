import IncidentKakaoMap from "./IncidentKakaoMap";
import React, {useEffect, useRef, useState} from "react";
import {Box, Button} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import btrPinImg from '../../../assets/image/map_pin01.png'
import oosPinImg from '../../../assets/image/map_pin02.png'
import prePinImg from '../../../assets/image/map_pin04.png'
import axiosInstance from "../../../components/util/AxiosUtil";
import {useForm} from "react-hook-form";
import CustomAutocomplete from "../../../components/ui/CustomAutocomplete";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import MapIcon from '@mui/icons-material/Map';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomTextField from "../../../components/ui/CustomTextField";
import Alert from "../../../components/ui/Alert";
import EmergencyShareIcon from '@mui/icons-material/EmergencyShare';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ButtonGroup from '@mui/material/ButtonGroup';

const defaultValues = {
    siteSeq: "",
    siteNm: "",
    oosSeq: 0,
    lat: 0,
    lng: 0,
    oosTypeCd: "",
    oosTypeNm: "",
    rtuSeq: "",
}

const searchValues = {
    siteSeq: "",
    siteNm: "",
    kmRange: '10',
    lat: 0,
    lng: 0,
    adr: "",
}

function IncidentDashboard() {

    const [open, setOpen] = useState(false); //사이트 정보 카드 오픈
    const [siteList, setSiteList] = useState([{dtlCd: "", dtlCdNm: "전체"}]); //사이트 목록

    const [movelatLng, setMoveLatLng] = useState({lat: 0, lng: 0});
    const [tabIdx, setTabIdx] = useState('0'); //상세정보 메뉴탭

    /* 주변고장정보 관련 */
    const [locOosList, setLocOosList] = useState([]); //핀 주변 고장목록

    /* CSS SX STYLE */
    const modalFullStyle = { width: '100%', 'textAlign': 'center', display: 'flex',pr:1, pl:1 };
    const modalHalfStyle = { width: '50%', 'textAlign': 'center', pr:1, pl:1};

    // Alert ref 객체
    const alertRef= useRef();

    // dtl 기본세팅 form
    const {
        handleSubmit,
        getValues,
        control,
        setValue ,
        reset,
        formState: { errors }
    } = useForm( {defaultValues} );

    const {
        handleSubmit : searchHandleSubmit,
        control : searchControl,
        setValue : searchSetValue,
        getValues : searchGetValues,
        reset: searchReset,
        formState: { errors : searchError }
    } = useForm({searchValues})


    //사이트 검색 목록 생성
    const getSiteCodeList = () => {
        axiosInstance.get('/siteCodeList').then((Response)=>{
            if (Response.request.status === 200) {
                const list  = [{dtlCd: "", dtlCdNm: "전체"}, ...Response.data.list];
                setSiteList(list);

                //site 검색 선택 정보 초기화
                setValue("siteSeq", "");
            }
        });
    }

    //클릭된 핀 주소 반환
    const getDtlInfo = (info) => {

        const kmRange = searchGetValues().kmRange ? searchGetValues().kmRange : '10';
        searchSetValue("siteSeq", "");
        searchSetValue("siteNm", "");
        searchSetValue("kmRange", kmRange);

        reset(info);
        setOpen(true);
    }

    const search = (data) => {
        if(!data){
            alertRef.current.handleOpen("확인", "이동할 사이트 정보를 선택해주세요.");
            return;
        }

        if(!data.siteSeq){
            alertRef.current.handleOpen("확인", "사이트정보를 확인해주세요.");
            return;
        }

        axiosInstance.get('/getOosLatLngInfo', {params: {siteSeq: data.siteSeq}}).then((Response)=>{
            if (Response.request.status === 200) {

                if(!Response.data.info){
                    alertRef.current.handleOpen("확인", "해당 사이트의 위경도 정보가 없습니다.");
                    return;
                }
                if(!Response.data.info.lat){
                    alertRef.current.handleOpen("확인", "해당 사이트의 위도 정보가 없습니다.");
                    return;
                }
                if(!Response.data.info.lng){
                    alertRef.current.handleOpen("확인", "해당 사이트의 경도 정보가 없습니다.");
                    return;
                }
                const info = {open: false, ...Response.data.info};

                setTabIdx('1');
                getLocationList(info.lat, info.lng, null);

                reset({defaultValues});
                setMoveLatLng(info);
            }
        });
    }

    //km 변경 함수
    const kmRangeChg = (km) => {

        searchSetValue("kmRange", km);

        if(!searchGetValues().siteSeq){
            pinLocationHandler();
        }else{
            getLocationList(movelatLng.lat, movelatLng.lng, null);
        }

    }

    const pinLocationHandler = () => {
        setTabIdx('1');
        if(!getValues().lat || !getValues().lng){
            return;
        }
        let lat = getValues().lat;
        let lng = getValues().lng;
        let oosSeq = getValues().oosSeq;

        getLocationList(lat, lng, oosSeq);
    }

    //핀 주변 고장목록 조회
    const getLocationList = (lat, lng, oosSeq) => {
        let kmRange = searchGetValues().kmRange ? searchGetValues().kmRange : '10';

        axiosInstance.get('/getLocationList',
            {params: {oosSeq:oosSeq, lat:lat, lng:lng, kmRange:kmRange}})
            .then((Response)=>{
                if (Response.request.status === 200) {
                    setLocOosList(Response.data.list);
                }
        });
    }

    const movelatLngHandler = (row) => {
        const info = {
            oosSeq: row.oosSeq,
            lat: row.lat,
            lng: row.lng,
            open: true
        }
        searchReset({searchValues});
        setMoveLatLng(info);
    }

    //주변 고장정보 view
    const LocationOosView = () => {
        const buttons = [
            <Button key="10" onClick={()=>kmRangeChg('10')}>10km</Button>,
            <Button key="15" onClick={()=>kmRangeChg('15')}>15km</Button>,
            <Button key="20" onClick={()=>kmRangeChg('20')}>20km</Button>,
        ];
        return(
        <>
            {locOosList.length > 0
                ?<>
                    <ButtonGroup sx={{pl:2, pt:1, pb:1}} color="error" size="small" aria-label="Small button group">
                        {buttons}
                    </ButtonGroup>
                    <Grid container spacing={1} sx={{pt: 1, pr:2, pl:2, pb:1, mb:1, maxHeight: '230px', width: '100%', overflowY: 'auto', display: 'flex'}}>
                        {locOosList.map((row, idx) => (
                            <Grid className="inc_map_location_box" key={idx} onClick={()=> movelatLngHandler(row)}>
                                <div>{row.siteNm}</div>
                                <div>
                                    <span style={{"fontWeight":"700"}}>{row.km}</span><span style={{"fontSize":"11px"}}>km</span>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </>
                : <Grid sx={{p:2, color:'red'}}>선택된 주변 고장정보가 없습니다.</Grid>
            }
        </>
        )
    }

    const goToKakao = () => {
        const lat = searchGetValues().lat;
        const lng = searchGetValues().lng;
        const adr = searchGetValues().adr ? searchGetValues().adr : "목적지";

        if(!lat){
            alertRef.current.handleOpen("확인", "위치 정보를 확인해주세요.");
            return;
        }

        if(!lng){
            alertRef.current.handleOpen("확인", "위치 정보를 확인해주세요.");
            return;
        }

        let link = 'https://map.kakao.com/link/to/'+adr+','+lat+','+lng;
        //해당 링크 이동
        //window.open(link);

        const popup = window.open(undefined, "link");
        popup.location.href = link;

    }

    const adressSetting = (lat, lng, adr) => {
        searchSetValue("lat", lat);
        searchSetValue("lng", lng);
        searchSetValue("adr", adr);
    }

    useEffect(() => {
        //사이트 정보 목록 조회
        getSiteCodeList();
    }, []);

    return (
        <div className="inc_main">
            {/* kakao map api custom */}
            <IncidentKakaoMap height='95%' getDtlInfo={getDtlInfo} movelatLng={movelatLng} setOpen={setOpen} setTabIdx={setTabIdx} searchSetValue={searchSetValue} adressSetting={adressSetting}/>

            <Grid container className="inc_map_search_box">
                <Grid xs={10} sx={{ mr: 2 }} className="inc_site_search_fff">
                    <CustomAutocomplete name="siteSeq"
                                        label="사이트"
                                        options={siteList}
                                        value="dtlCd"
                                        text="dtlCdNm"
                                        control={searchControl}
                                        getValues={searchGetValues}
                                        errors={searchError.siteSeq}
                    />
                </Grid>
                {/* 검색버튼 */}
                <IconButton color="primary"
                            className="inc_map_search_icon"
                            sx={{p: 1, width: '40px', height: '40px'}}
                            onClick={searchHandleSubmit(search)}
                ><SearchIcon/></IconButton >
            </Grid>

            <div className={`inc_map_accordian ${open ? 'open' : ''}`}>
                <div style={{"display": "flex", "marginBottom": "10px", "justifyContent": "flex-end"}}>
                    <div className="inc_map_pin_info">
                        <div style={{"width": "33%"}}>
                            <div className="inc_info_Pin_box">
                                <img src={oosPinImg} alt="" className="inc_info_Pin_style"/>
                            </div>
                            <div className="inc_map_pin_text">고장</div>
                        </div>
                        <div style={{"width": "33%"}}>
                            <div className="inc_info_Pin_box">
                                <img src={prePinImg} alt="" className="inc_info_Pin_style"/>
                            </div>
                            <div className="inc_map_pin_text">준비중</div>
                        </div>
                        <div style={{"width": "33%"}}>
                            <div className="inc_info_Pin_box">
                                <img src={btrPinImg} alt="" className="inc_info_Pin_style"/>
                            </div>
                            <div className="inc_map_pin_text">출장</div>
                        </div>
                    </div>
                </div>
                <div className="inc_dtl">
                    <Grid container xs={12} sx={{p: 0, height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Button variant="text"
                                className="inc_map_dtl_svg"
                                onClick={e => setOpen(!open)}
                                sx={{p: 1, width: '100%', height: '40px'}}
                        >{open
                            ? <ExpandMoreIcon color="action" preserveAspectRatio="none" viewBox="0 5 25 15" sx={{width: '100px', height: '35px'}}/>
                            : <ExpandLessIcon color="action" preserveAspectRatio="none" viewBox="0 5 25 15" sx={{width: '100px', height: '35px'}}/>}
                        </Button >
                    </Grid>
                    <Grid container xs={12} sx={{pl:2,pr:2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{
                            fontWeight: "bold",
                            boxShadow: "inset 0 -8px 0 #bbdefb",
                            width: "fit-content"
                        }}>
                            <span style={{"fontSize": "min(2.5vmax, 20px)"}}>
                                {tabIdx === '0' 
                                    ? '상세정보'
                                    : (tabIdx === '1')
                                        ? '선택장소 주변 고장정보'
                                        : 'other'
                                }
                            </span>
                        </div>
                        {/* 버튼 모음 */}
                        <div>
                            <IconButton color="primary"
                                        className="inc_map_link_icon"
                                        sx={{p: 1}}
                                        onClick={()=> setTabIdx('0')}
                            ><span style={{"fontSize":"17px","fontWeight":"800"}}>i</span></IconButton >
                            <IconButton color="primary"
                                        className="inc_map_link_icon"
                                        sx={{p: 1, ml:1}}
                                        onClick={pinLocationHandler}
                            ><EmergencyShareIcon/></IconButton >
                            <IconButton color="primary"
                                        className="inc_map_link_icon"
                                        sx={{p: 1, ml:1}}
                                        onClick={goToKakao}
                            ><MapIcon/></IconButton >
                        </div>
                    </Grid>
                    {/* 상세정보 */}
                    {tabIdx === '0'
                    ?
                        <Grid container spacing={1} sx={{pt: 1, pr:2, pl:2, pb:1, mb:1}}>
                            <Grid sx={modalHalfStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="pjtNm"
                                    label="프로젝트"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.pjtNm}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalHalfStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="nrnGentNm"
                                    label="에너지원"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.nrnGentNm}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalHalfStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="siteNm"
                                    label="사이트명"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.siteNm}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalHalfStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="tlno"
                                    label="연락처"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.tlno}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalFullStyle}>
                                <CustomTextField
                                    fullWidth
                                    multiline
                                    rows={'2'}
                                    sx={{width:'100%'}}
                                    name="oosDtl"
                                    label="장애상세"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.oosDtl}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalFullStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="adr"
                                    label="주소"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.adr}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                            <Grid sx={modalFullStyle}>
                                <CustomTextField
                                    fullWidth
                                    sx={{width:'100%'}}
                                    name="rmark"
                                    label="비고"
                                    size="small"
                                    variant="standard"
                                    control={control}
                                    errors={errors.rmark}
                                    InputProps={{ readOnly: true, }}
                                />
                            </Grid>
                        </Grid>
                    : tabIdx === '1'
                            ?<LocationOosView />
                            :null
                    }

                </div>
            </div>

            <Alert ref={alertRef} />
        </div>
    )
}

export default IncidentDashboard;