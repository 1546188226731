import React, { Component, useRef } from 'react'
import  { getHttpAuthHeader } from '../../../components/util/AuthUtil';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css';
import '../../../assets/css/DataTable.css';

const btnDeactive ={
    position: 'relative',
    border: '0',
    display: 'inline-block',
    textAlign: 'center',
    color: 'white',
    background: 'rgb(177 184 191)',
    padding: '4px 12px',
    fontSize: '12px',
    width: '70px'
}

const btnActive = {
    position: 'relative',
    border: '0',
    display: 'inline-block',
    textAlign: 'center',
    background: '#6698cb',
    color: 'white',
    padding: '4px 12px',
    fontSize: '12px',
    width: '70px'
}

const mr = {
    marginRight: '2px'
}

// Korean
const lang_kor = {
    "decimal" : "",
    "emptyTable" : "데이터가 없습니다.",
    "info" : "_START_ - _END_ (총 _TOTAL_)",
    "infoEmpty" : "0",
    "infoFiltered" : "(전체 _MAX_ 명 중 검색결과)",
    "infoPostFix" : "",
    "thousands" : ",",
    "lengthMenu" : "_MENU_ 개씩 보기",
    "loadingRecords" : "로딩중...",
    "processing" : "",
    "search" : "검색 : ",
    "zeroRecords" : "검색된 데이터가 없습니다.",
    "paginate" : {
        "first" : "첫 페이지",
        "last" : "마지막 페이지",
        "next" : "다음",
        "previous" : "이전"
    },
    "aria" : {
        "sortAscending" : " :  오름차순 정렬",
        "sortDescending" : " :  내림차순 정렬"
    }
};

class CustomDatatable extends Component {

    constructor(props){

        super(props);

        this.state = {
            table: null,
            pageLength: 10
        }

        this.handleClick = this.handleClick.bind(this);

        this.handlePageButtonClick = this.handlePageButtonClick.bind(this);

        this.handleChange = this.handleChange.bind(this);

        this.getFilterData = this.getFilterData.bind(this);

        this.pageChange = this.pageChange.bind(this);
    }

    //버튼 클릭 이벤트
    handleClick(e) {
        this.props.buttonClick(e);
    }

    //페이지 버튼 클릭 이벤트
    handlePageButtonClick(e, pageLength) {
        this.setState({
            pageLength: pageLength
        });

        this.state.table.page.len(pageLength).draw();
    }

    //체크박스 값 변경 이벤트
    handleChange(e) {

        let datasetList = [];

        if (e.target.id === "checkall"){
            $('input[type=checkbox]').each(function(){
                if (this.id != "checkall"){
                    $(this).prop('checked', e.target.checked);
                    if(e.target.checked){
                        datasetList.push(this.dataset);
                    }
                }
            })
        }else{
            $('input[type=checkbox]').each(function(){
                if (this.checked){
                    datasetList.push(this.dataset);
                }
            });
        }

        this.props.changeEvent(e, datasetList);
    }

    pageChange(e) {
        if (this.props.pageChangeEvent != null){
            let info = this.state.table.page.info();
            this.props.pageChangeEvent(e, info.page);
        }
    }

    getFilterData(data){
        const datatableFilter = JSON.parse($('#datatable-filter-' + this.props.id).val());
        return $.extend(data, datatableFilter);
    }

    componentDidMount(){

        this.setState({
            pageLength: Number(this.props.pageLength)
        });

        this.state.table = $('#' + this.props.id).DataTable({
            ajax: {
                type: 'GET',
                url: `${process.env.REACT_APP_API_URL}` + this.props.url,
                dataType: 'json',
                data: this.getFilterData,
                beforeSend(request) {
                    request.setRequestHeader('Authorization', getHttpAuthHeader());
                },
                dataSrc(data) {
                    return data.list;
                }
            },
            // responsive: {
            //     details: false
            // },
            processing: true,
            columns: this.props.columns,
            columnDefs: this.props.columnDefs,
            createdRow: this.props.createdRow ? this.props.createdRow : null,
            language : lang_kor,
            serverSide : true,
            scrollCollapse: false,
            scroller: true,
            scrollX: true,
            searching: false,
            lengthChange: false,
            info: true,
            select: true,
            paging : this.props.paging,
            pageLength: this.props.pageLength,
            destroy: true,
            order: this.props.order,
        })

        this.handleClick = this.state.table.on('click', 'button', this.handleClick);

        this.handleChange = this.state.table.on('change', 'input[type="checkbox"]', this.handleChange);

        this.pageChange = this.state.table.on('page.dt', this.pageChange);
    }

    componentDidUpdate(prevProps){
        if (this.props.filter !== prevProps.filter){

            this.state.table.ajax.params({...this.props.filter});

            this.state.table.ajax.reload(null, this.props.pageFixed);
        }
    }

    //테이블을 고정시켜서 여기에선 사용 안됨
    componentWillUnmount(){
        if (this.state.table){
            this.state.table.destroy();
        }
    }

    render() {

        return (
            <div style={{ width: this.props.width }}>
                <div style={{ display:'inline-block', float:'right', padding:'5px' }}>
                    <button onClick={(e)=>{this.handlePageButtonClick(e, 10)}} style={ this.state.pageLength === 10 ? btnActive : btnDeactive }>10</button>
                    <button onClick={(e)=>{this.handlePageButtonClick(e, 25)}} style={ this.state.pageLength === 25 ? btnActive : btnDeactive }>25</button>
                    <button onClick={(e)=>{this.handlePageButtonClick(e, 50)}} style={ this.state.pageLength === 50 ? btnActive : btnDeactive }>50</button>
                    <button onClick={(e)=>{this.handlePageButtonClick(e, 100)}} style={ this.state.pageLength === 100 ? btnActive : btnDeactive }>100</button>
                    <button onClick={(e)=>{this.handlePageButtonClick(e, 200)}} style={ this.state.pageLength === 200 ? btnActive : btnDeactive }>200</button>
                </div>
                <table id={ this.props.id } className='celled table nowrap' style={{ width: this.props.width }}>{/*table celled display gray-head nowrap*/}
                    <thead>
                    <tr>
                        {
                            this.props.columns.map((column, index) => (
                                <th key={index}>{column.html ? column.html : column.title}</th>
                            ))
                        }
                    </tr>
                    </thead>
                </table>
                <input id={"datatable-filter-" + this.props.id} type="hidden" value={JSON.stringify(this.props.filter)}></input>
            </div>
        )
    }
}

export default CustomDatatable;