import Grid from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import IncidentTable2 from "../sts/IncidentTable2";
import {useForm} from "react-hook-form";
import $ from "jquery";
import IncidentActnSearch from "./IncidentActnSearch";

const defaultValues = {
    pjtSeq: '',                 /* 프로젝트 일련번호 */
    sido : '',                  /* 시도 */
    sigungu : '',               /* 군구 */
    nrnGentCd : '',             /* 에너지원코드 */
    siteSeq : '',               /* 사이트 일련번호 */
    actionRsltCd : '',             /* 조치 결과 코드 */
    oosTypeCd: '',              /* 장애 유형 코드 */
    actnDt: '',                  /* 조치일 */
    btrPamDate: '',             /* 출장일 */
    resultYnNm: '',             /* 조치유뮤 */
}

function IncidentActn(){

    //[검색]조회 기본세팅
    const { handleSubmit,
        control,
        getValues,
        setValue,
        formState: { errors }
    } = useForm({defaultValues});

    // 데이터테이블 페이징 유지 여부
    const [ pageFixed, setPageFixed ] = useState(true);
    // 검색필터
    const [filterData, setFilterData] = useState( {defaultValues});

    const [checkList, setCheckList] = useState([]);

    //조회버튼 클릭 콜백함수
    const search = (form) => {
        setPageFixed(true);
        setFilterData(form);
    }

    //테이블 커스텀 컬럼 정보
    const columnDefs = [
        {
            "targets": [0], render: function (data, type, row, meta) {
                return "<label><input type='checkbox' data-oosSeq='" + row.oosSeq + "'></label>";
            }
        },
        {
            "target": [11], createdCell: function (td, cellData, rowData, row, col) {
                $(td).css({
                    'max-width': '500px', // 최대 너비 설정
                });
            }
        }
    ]

    const createdRow = (row, data, dataIndex) => {
        if(!data.oosClrDt){
            $(row).css('color', 'red');
        }

        // if(data.resultYnNm == "미조치"){
        //     $(row).css('color', 'red');
        // }
    }

    //테이블 체크박스 변경 콜백 함수
    const changeEvent = (e, datasetList) => {
        let strOosSeqList=[];
        datasetList.map((item) => {
            if(item.oosseq){
                strOosSeqList.push(item.oosseq);
            }
        })
        setCheckList(strOosSeqList);
    }

    //전체 inputbox 클릭 이벤트
    const checkAllEvt = (e) => {
        let datasetList = [];
        $('input[type=checkbox]').each(function(){
            if (this.id != "checkall"){
                $(this).prop('checked', e.target.checked);
                datasetList.push(this.dataset);
            }
        })
        //전체 input 박스 클릭시 css적으로도 적용
        const rows = document.querySelectorAll('tr');
        if(e.target.checked){
            rows.forEach(row => {
                row.classList.add('incident_selected_row'); // 'new-class'는 추가할 클래스 이름
            });
        }else{
            datasetList = [];
            rows.forEach(row => {
                row.classList.remove('incident_selected_row');
            });
        }
        changeEvent(e, datasetList);
    }

    //테이블 컬럼정보
    const columns =  [
        { id: "oosSeq", data: "oosSeq", html: <input type='checkbox' id='checkall' onClick={checkAllEvt}/>, width:60, className: "text-center incident_fixed", orderable: false, responsivePriority: 1 },
        { id: "pjtNm", data: "pjtNm", title: '프로젝트', width:100, className: "text-center incident_fixed incident_bd_right",responsivePriority: 2 },
        { id: "nrnGentNm", data: "nrnGentNm", title: '에너지원', width:100, className: "text-center", responsivePriority: 3 },
        { id: "sido", data: "sido", title: '시도', width:100,className: "text-center", responsivePriority: 4 },
        { id: "sigungu", data: "sigungu", title: '구군', width:100,className: "text-center", responsivePriority: 5 },
        { id: "numRowYy", data: "numRowYy", title: '사업연도', width:100,className: "text-center", responsivePriority: 6 },
        { id: "siteNm", data: "siteNm", title: '사이트', width:100,className: "text-center", responsivePriority: 7 },
        { id: "rtuSeq", data: "rtuSeq", title: 'RTU', width:100,className: "text-center", responsivePriority: 8 },
        { id: "tlno", data: "tlno", title: '전화번호', width:100,className: "text-center", responsivePriority: 9 },
        { id: "adr", data: "adr", title: '주소', width:200,className: "text-center", responsivePriority: 10 },
        { id: "oosTypeNm", data: "oosTypeNm", width:100, title: '장애유형',className: "text-center", responsivePriority: 11 },
        { id: "oosDtl", data: "oosDtl", title: '장애상세', width:250,className: "text-center", responsivePriority: 12 },
        { id: "btrPamDate", data: "btrPamDate", width:100, title: '출장일정',className: "text-center", responsivePriority: 13 },
        { id: "actnr", data: "actnr", title: '조치자', width:100,className: "text-center", responsivePriority: 14 },
        { id: "resultYnNm", data: "resultYnNm", title: '조치여부', width:100,className: "text-center", responsivePriority: 15 },
        { id: "actnDt", data: "actnDt", title: '조치일자', width:100,className: "text-center", responsivePriority: 16 },
        { id: "oosOccuDt", data: "oosOccuDt",visible: false, title: '장애발생일', width:100,className: "text-center", responsivePriority: 17 }
    ]

    //테이블 row 선택 이벤트
    useEffect(() => {
        document.getElementById('incident_table_2').addEventListener('click', function (e) {
            const row = e.target.closest('tr'); // 클릭된 요소가 포함된 <tr> 찾기

            if (row) {
                const inputBox = row.querySelector('input[type="checkbox"]'); // <input> 찾기
                if (inputBox) {
                    //체크박스와 row선택 이벤트의 중복 발생 방지
                    if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
                        if(!inputBox.checked){
                            row.classList.remove('incident_selected_row');
                        }else{
                            row.classList.add('incident_selected_row');
                        }
                        e.stopPropagation(); // 이벤트 전파 중단
                        return;
                    }
                    inputBox.checked = !inputBox.checked;

                    if(!inputBox.checked){
                        row.classList.remove('incident_selected_row');
                    }else{
                        row.classList.add('incident_selected_row');
                    }
                    let datasetList = [];
                    $('input[type=checkbox]:checked').each(function(){
                        if (this.id != "checkall"){
                            datasetList.push(this.dataset);
                        }
                    })
                    changeEvent(inputBox, datasetList);
                }
            }
        });
    }, []);

    return(
        <div style={{
            "padding": "16px 24px",
            "color": "rgb(68, 89, 110)",
            "height": "100%"
        }}>
            <Grid container spacing={2} /* sx={{ height:'100%'}} */>
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height:'100%', p:0 }}>
                    <div style={{"marginBottom": "28px", "marginTop": "16px"}}>
                        <p fontWeight="600" className="css-1pr8naw">장애 조치</p>
                        <p className="css-3vvtry">PoSE / 영업 지원 / 장애 조치</p>
                    </div>
                </Grid>

                {/* 검색창 컴포넌트(내용이 많아 따로 분리) */}
                <IncidentActnSearch search={search} checkList={checkList}/>

                {/* 데이터테이블 */}
                <Grid
                    container
                    xs={12} sm={12} md={12} lg={12} xl={12}
                    className="page-filter"
                    sx={{ p: 2, mt: 3 }}
                >
                    <Grid xs={12} sm={12} md={12} lg={12} xl={12}>
                        <IncidentTable2 id="incident_table_2"              //Table ID (페이지에서 고유한 값으로 정의 해야 합니다.)
                                        url="/getIncidentActnList"                   //ajax url
                                        columns={columns}                  //테이블 컬럼 정의
                                        filter={filterData}              //ajax 검색 필터 정의
                                        columnDefs={columnDefs}                  //Custom 컬럼 정의
                                        paging={true}                    //페이징 여부
                                        pageLength="10"                  //페이징 수
                                        pageFixed={pageFixed}            //페이징 유지 여부
                                        order={[[16, 'desc']]}             //기본 정렬
                                        width='100%'
                                        changeEvent={changeEvent}                //체크값 변경 콜백함수
                                        createdRow={createdRow}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default IncidentActn;